import React, { ReactNode, useRef } from 'react'
import Select, {
  MultiValue,
  SingleValue as SingleValueType,
  components,
} from 'react-select'

import { Check as CheckIcon } from '../../../icons'

import styles from './CustomSelect.module.scss'

const { Option, SingleValue } = components

export interface ISelectOption {
  label: string
  value: string
  options?: ISelectOption[]
  icon?: ReactNode
}

export interface ISelectProp {
  value: ISelectOption | Array<ISelectOption> | null
  options: Array<ISelectOption>
  placeholder?: string
  onBlur?: any
  className?: string
  id?: string
  name?: string
  disabled?: boolean
  isMulti?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  error?: string
  size?: 'm' | 'l'
  type?: 'default' | 'ghost'
  onChange: (
    newValue: MultiValue<ISelectOption> | SingleValueType<ISelectOption>
  ) => void
}

const IconOption = (props: any) => {
  return (
    <Option {...props}>
      {!!props.data.icon && (
        <div
          className={styles.iconWrapper}
          style={{ marginRight: '4px', paddingBottom: '1px' }}
        >
          {props.data.icon}
        </div>
      )}
      {props.data.label}
      {props.isSelected && (
        <div style={{ marginLeft: 'auto' }}>
          <CheckIcon />
        </div>
      )}
    </Option>
  )
}

const ValueOption = (props: any) => (
  <SingleValue {...props}>
    {!!props.data.icon && (
      <div
        className={styles.iconWrapper}
        style={{ marginRight: '4px', paddingBottom: '1px' }}
      >
        {props.data.icon}
      </div>
    )}
    {props.data.label}
  </SingleValue>
)

const CustomSelect: React.FC<ISelectProp> = (props) => {
  const {
    value,
    placeholder,
    options,
    className,
    isMulti,
    isSearchable,
    isClearable,
    name,
    onBlur,
    disabled,
    id,
    type,
    onChange,
  } = props

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      display: 'block',
      flex: '1',
      lineHeight: '20px',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: type === 'ghost' ? 'none' : '#ffff',
      borderWidth: type === 'ghost' ? '0' : '1px',
      width: '100%',
      minHeight: type === 'ghost' ? 'unset' : '40px',
      // height: '36px',
      borderColor: props.error
        ? 'var(--critical100)'
        : state.selectProps.menuIsOpen
          ? 'var(--gray30)'
          : 'var(--gray10)',
      boxShadow:
        type === 'ghost'
          ? 'none'
          : state.selectProps.menuIsOpen
            ? `0 0 0 1px ${
                props.error ? 'var(--critical100)' : 'var(--brand-primary)'
              }`
            : 'none',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0 0 0 1px ${
          props.error ? 'var(--critical100)' : 'var(--gray30)'
        }`,
        borderColor: `${props.error ? 'var(--critical100)' : 'var(--gray30)'}`,
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '3px 0 3px 10px',
      fontSize: type === 'ghost' ? '14px' : '16px',
      fontWeight: type === 'ghost' ? '700' : '500',
      textDecoration: type === 'ghost' ? 'underline' : 'none',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      maxHeight: '100%',
      paddingRight: '12px',
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      padding: '3px 8px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      padding: '3px 8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      color: '#000',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      left: '15px',
      color: 'var(--gray60)',
    }),
    menu: (provided: any) => ({
      ...provided,
      margin: '4px 0',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
      borderRadius: '8px',
      border: 'none',
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: '8px 0',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      // justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '9px 16px',
      backgroundColor: state.isSelected
        ? '#DAE4FD'
        : state.isFocused
          ? '#F6F8FF'
          : 'transparent',
      color: state.isSelected ? '#22252F' : '#22252F',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    input: (provided: any) => ({
      ...provided,
      fontSize: type === 'ghost' ? '14px' : '16px',
      lineHeight: '18px',
    }),
  }

  const valueRef = useRef(props.value)
  valueRef.current = props.value

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: 'CustomSelect all',
  }

  const isSelectAllSelected = () => {
    if (!!valueRef && isMulti) {
      // @ts-ignore
      return valueRef.current?.length === options.length
    } else {
      return false
    }
  }

  const isOptionSelected = (option: ISelectOption) => {
    if (isMulti) {
      return (
        // @ts-ignore
        valueRef.current?.some(({ value }) => value === option.value) ||
        isSelectAllSelected()
      )
    } else {
      // @ts-ignore
      return valueRef?.current?.value === option.value
    }
  }

  const getOptions = () => {
    if (isMulti) {
      return [selectAllOption, ...options]
    } else {
      return options
    }
  }

  const getValue = () => {
    if (isMulti) {
      return isSelectAllSelected() ? [selectAllOption] : value
    } else {
      return value
    }
  }

  const handleChange = (selectedOption: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta

    if (isMulti) {
      if (
        action === 'select-option' &&
        option.value === selectAllOption.value
      ) {
        props.onChange(props.options)
      } else if (
        (action === 'deselect-option' &&
          option.value === selectAllOption.value) ||
        (action === 'remove-value' &&
          removedValue.value === selectAllOption.value)
      ) {
        onChange([])
      } else if (
        actionMeta.action === 'deselect-option' &&
        isSelectAllSelected()
      ) {
        onChange(options.filter(({ value }) => value !== option.value))
      } else {
        props.onChange(selectedOption || [])
      }
    } else {
      props.onChange(selectedOption)
    }
  }

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <Select
        id={id}
        name={name}
        styles={customStyles}
        value={getValue()}
        placeholder={placeholder}
        options={getOptions()}
        onChange={handleChange}
        onBlur={onBlur}
        isMulti={isMulti}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOptionSelected={isOptionSelected}
        components={{ Option: IconOption, SingleValue: ValueOption }}
      />
    </div>
  )
}

export default CustomSelect
