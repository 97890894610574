import React from 'react'

import styles from './TabsList.module.scss'

interface ITabsListProps {
  items: ITabItem[]
  activeTabIndex: number
  onChange: (index: number, item?: ITabItem) => void
  className?: string
  size?: 'm' | 'l'
  color?: 'white' | 'blue'
  bordered?: boolean
  wide?: boolean
}

export interface ITabItem {
  label: string
  value?: string
  note?: string
}

const TabsList: React.FC<ITabsListProps> = (props) => {
  const {
    items,
    activeTabIndex,
    className,
    bordered,
    size,
    color,
    wide,
    onChange,
  } = props

  const handleTabClick = (index: number, item: ITabItem) => {
    onChange(index, item)
  }

  const itemsList = items.map((item, index) => (
    <button
      className={`
        ${styles.item}
        ${activeTabIndex === index ? styles.active : ''}
        ${wide ? styles.wide : ''}
        ${styles[size || 'm']}
        ${styles[color || 'white']}
      `}
      key={`tabs-list-item_${index}`}
      onClick={() => handleTabClick(index, item)}
    >
      {item.label}

      {!!item.note && <div className={styles.itemNote}>{item.note}</div>}
    </button>
  ))

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
        ${styles[size || 'm']}
        ${bordered ? styles.bordered : ''}
      `}
    >
      {itemsList}
    </div>
  )
}

export default TabsList
