import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import styles from './SettingsContent.module.scss'

import TabsList from 'components/elements/tabs/TabsList'
import TabPanels from 'components/elements/tabs/TabPanels'
import AccountSettings from 'components/modules/settings/AccountSettings'
import VerificationSettings from 'components/modules/settings/VerificationSettings'
import SecuritySettings from 'components/modules/settings/SecuritySettings'
import APIKeySettings from 'components/modules/settings/APIKeySettings'
import SubscriptionSettings from 'components/modules/settings/SubscriptionSettings'

const SettingsContent: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const activeSettingsParam = searchParams.get('active_settings')

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const tabItems = [
    {
      label: 'Account',
      value: 'account',
    },
    {
      label: 'Verification',
      value: 'verification',
    },
    {
      label: 'Security',
      value: 'security',
    },
    {
      label: 'API key',
      value: 'api_key',
    },
    {
      label: 'Subscription & Billing',
      value: 'subscription',
    },
    /*{
      label: 'Notifications',
    },
    {
      label: 'Team management',
    },*/
  ]

  const settingsTypes = tabItems.map((item) => item.value)

  useEffect(() => {
    const activeSettingsParamIndex = activeSettingsParam
      ? settingsTypes.indexOf(activeSettingsParam)
      : null

    if (
      activeSettingsParamIndex &&
      activeSettingsParamIndex !== activeTabIndex
    ) {
      setActiveTabIndex(activeSettingsParamIndex)
    }
  }, [settingsTypes, activeSettingsParam, activeTabIndex])

  const tabContentArray = [
    <AccountSettings />,
    <VerificationSettings />,
    <SecuritySettings />,
    <APIKeySettings />,
    <SubscriptionSettings />,
  ]

  const handleTabChange = (index: number, item: any) => {
    setActiveTabIndex(index)
    setSearchParams({ active_settings: item.value })
  }

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <TabsList
          items={tabItems}
          activeTabIndex={activeTabIndex}
          onChange={handleTabChange}
        />
      </div>

      <div className={styles.content}>
        <TabPanels items={tabContentArray} activeTabIndex={activeTabIndex} />
      </div>
    </section>
  )
}

export default SettingsContent
