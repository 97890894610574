import jsonData from './builder_json.json'

export const JSONData = jsonData

export const getApiTypes = () => {
  let apiTypes = []

  for (let type of Object.keys(jsonData)) {
    apiTypes.push({
      label: type,
      value: type,
    })
  }

  return apiTypes
}

export const getSelectOptions = (data) => {
  let apiTypes = []

  for (let type of Object.keys(data)) {
    apiTypes.push({
      label: type,
      value: type,
    })
  }

  return apiTypes
}

export const isLastLevel = (object) =>
  Object.keys(object).indexOf('examples') >= 0
