import React, { useState } from 'react'

import styles from './SecuritySettings.module.scss'

import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'
import CustomButton from 'components/elements/buttons/CustomButton'

const SecuritySettings: React.FC = () => {
  const twoFactorAuthStatus: string = 'fail'

  const [newPassword, setPassword] = useState('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleUpdatePasswordClick = () => {}
  const handleTurnOffClick = () => {}
  const handleStartClick = () => {}

  const renderTwoFactorAuthInfo = () => {
    if (twoFactorAuthStatus === 'linked') {
      return (
        <>
          <p className={styles.description}>
            Authentication app linked Nov 21, 2023
          </p>

          <CustomButton color={'red'} onClick={handleTurnOffClick}>
            Turn off
          </CustomButton>
        </>
      )
    } else {
      return (
        <>
          <p className={styles.description}>No any Authentication app linked</p>

          <CustomButton onClick={handleStartClick}>Start setup</CustomButton>
        </>
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <h3 className={styles.title}>Change password</h3>

        <InputGroup
          className={styles.inputGroup}
          labelTop={'Type new password'}
          // error={}
        >
          <CustomInput
            name={'new_password'}
            value={newPassword}
            onChange={handleInputChange}
          />
        </InputGroup>

        <CustomButton
          className={styles.btn}
          onClick={handleUpdatePasswordClick}
          // loading={verifProcessing}
        >
          Update
        </CustomButton>
      </div>

      <div className={styles.block}>
        <h3 className={styles.title}>Two-factor authentication (2FA)</h3>

        {renderTwoFactorAuthInfo()}
      </div>
    </div>
  )
}

export default SecuritySettings
