import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTimer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.333 2.515a.625.625 0 1 1 0-1.25h2.946a.625.625 0 1 1 0 1.25zM5.442 2.891a.625.625 0 0 1 0 .884L3.358 5.86a.625.625 0 0 1-.883-.884L4.558 2.89a.625.625 0 0 1 .884 0M10 4.792a6.042 6.042 0 1 0 6.041 6.041.625.625 0 0 1 1.25 0A7.292 7.292 0 1 1 10 3.542a.625.625 0 0 1 0 1.25"
    />
    <path
      fill="currentColor"
      d="M14.323 6.97a.625.625 0 0 0-.877-.877l-2.641 2.055L9.079 9.38a1.403 1.403 0 1 0 1.957 1.957l1.233-1.726z"
    />
  </svg>
)
export default SvgTimer
