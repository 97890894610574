import React, { InputHTMLAttributes } from 'react'

import styles from './CustomCheckbox.module.scss'

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  error?: boolean
  id: string
}

const CustomCheckbox: React.FC<ICheckboxProps> = (props) => {
  const {
    label,
    className,
    checked,
    value,
    id,
    name,
    disabled,
    onChange,
    onBlur,
    error,
    children,
    ...rest
  } = props

  return (
    <label
      className={`
        ${styles.container}
        ${disabled ? styles.disabled : ''}
        ${className ? className : ''}`}
    >
      <input
        type="checkbox"
        name={name}
        className={styles.input}
        value={value}
        checked={checked}
        // id={id}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />

      <div
        className={`
          ${styles.box}
          ${!!checked ? styles.checked : ''}
          ${error ? styles.errored : ''}
        `}
        // htmlFor={id}
      >
        <div className={styles.icon} />
      </div>

      {!!children && <div className={styles.label}>{children}</div>}
    </label>
  )
}

export default CustomCheckbox
