import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPresent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.209 4.583A2.708 2.708 0 0 1 10 2.853a2.708 2.708 0 0 1 4.366 3.189H15a2.29 2.29 0 0 1 2.292 2.291V10c0 .67-.452 1.235-1.068 1.405.12 1.523.05 3.055-.208 4.561a2.05 2.05 0 0 1-1.792 1.69l-.762.086c-2.3.257-4.623.257-6.924 0l-.762-.085a2.05 2.05 0 0 1-1.792-1.69 18.4 18.4 0 0 1-.208-4.562A1.46 1.46 0 0 1 2.71 10V8.333A2.29 2.29 0 0 1 5 6.042h.634a2.7 2.7 0 0 1-.426-1.459m4.166 0a1.458 1.458 0 1 0-2.917 0 1.458 1.458 0 0 0 2.917 0m2.709 1.459a1.458 1.458 0 1 0 0-2.917 1.458 1.458 0 0 0 0 2.917M3.959 8.333c0-.575.466-1.041 1.041-1.041h4.375v2.916H4.167A.21.21 0 0 1 3.959 10zm6.666 3.125h4.35a17.2 17.2 0 0 1-.19 4.297.8.8 0 0 1-.7.66l-.762.085q-1.345.15-2.698.178zm0-1.25h5.209a.21.21 0 0 0 .208-.208V8.333c0-.575-.467-1.041-1.042-1.041h-4.375zm-1.25 1.25v5.22a30 30 0 0 1-2.698-.178l-.762-.085a.8.8 0 0 1-.699-.66 17.2 17.2 0 0 1-.19-4.297z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgPresent
