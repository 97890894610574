import React from 'react'

import styles from './PlanStatus.module.scss'

interface IPlanStatusProps {
  status: 'active' | 'cancelled'
  className?: string
}

const statusLabels = {
  active: 'Active',
  cancelled: 'Cancelled',
}

const PlanStatus: React.FC<IPlanStatusProps> = ({ status, className }) => {
  return (
    <div
      className={`
        ${styles.status}
        ${styles[status]}
        ${className || ''}
      `}
    >
      {statusLabels[status]}
    </div>
  )
}

export default PlanStatus
