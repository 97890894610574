import React, { InputHTMLAttributes } from 'react'

import styles from './CustomRadio.module.scss'

export interface IRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  error?: boolean
  id: string
}

const CustomRadio: React.FC<IRadioProps> = (props) => {
  const {
    label,
    className,
    checked,
    value,
    id,
    name,
    disabled,
    onChange,
    onBlur,
    error,
    children,
    ...rest
  } = props

  return (
    <label
      className={`
        ${styles.container}
        ${disabled ? styles.disabled : ''}
        ${className ? className : ''}`}
    >
      <input
        type="radio"
        name={name}
        className={styles.input}
        value={value}
        checked={checked}
        id={id}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />

      <label
        className={`
          ${styles.box}
          ${!!checked ? styles.checked : ''}
          ${error ? styles.errored : ''}
        `}
        htmlFor={id}
      />

      {!!children && <div className={styles.label}>{children}</div>}
    </label>
  )
}

export default CustomRadio
