import React from 'react'
import { Tooltip } from 'react-tooltip'

import styles from './InputGroup.module.scss'

import { InfoCircle as InfoIcon } from '../../../icons'

import ValidationMessage from '../ValidationMessage/ValidationMessage'

interface IInputGroup {
  className?: string
  labelClassName?: string
  labelTop?: string
  labelLeft?: string
  description?: string
  name?: string
  error?: string
  bottomNotice?: string
  size?: 'm' | 'l'
  childrenDirection?: 'horizontal' | 'vertical'
  children?: React.ReactNode
}

const InputGroup: React.FC<IInputGroup> = (props) => {
  const {
    className,
    labelTop,
    labelLeft,
    labelClassName,
    size,
    error,
    description,
    name,
    childrenDirection,
    children,
    bottomNotice,
  } = props

  const labelSizeClass = size ? `label_${size}` : 'label_m'

  return (
    <label
      className={`
        ${styles.container}
        ${styles[labelSizeClass]}
        ${className ? className : ''}
      `}
    >
      {!!labelTop && (
        <div
          className={`
            ${styles.labelTop}
            ${labelClassName ? labelClassName : ''}
          `}
        >
          {labelTop}

          {!!description && (
            <div
              className={styles.infoIconWrapper}
              data-tip
              data-tooltip-id={`tooltip__${name}`}
            >
              <InfoIcon />
            </div>
          )}
        </div>
      )}

      <div className={styles.content}>
        {!!labelLeft && (
          <div
            className={`
              ${styles.labelLeft}
              ${labelClassName ? labelClassName : ''}
            `}
          >
            {labelLeft}
          </div>
        )}

        <div
          className={`
            ${styles.childrenWrapper}
            ${childrenDirection ? styles[childrenDirection] : styles.horizontal}
          `}
        >
          {children}
          {!!error && <ValidationMessage>{error}</ValidationMessage>}
          {!!bottomNotice && (
            <div className={styles.bottomNotice}>{bottomNotice}</div>
          )}
        </div>
      </div>

      <Tooltip id={`tooltip__${name}`} place={'bottom'}>
        {description}
      </Tooltip>
    </label>
  )
}

export default InputGroup
