import React from 'react'

import styles from './SubscriptionSettings.module.scss'

import SubscriptionInfo from '../SubscriptionsInfo'
import CustomButton from '../../../elements/buttons/CustomButton'

const SubscriptionSettings: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <h3 className={styles.title}>Subscription</h3>

        <SubscriptionInfo />

        <div className={styles.controls}>
          <CustomButton color={'outline'}>Change payment method</CustomButton>

          <CustomButton color={'outline'}>Cancel Subscription</CustomButton>
        </div>
      </div>

      <div className={styles.block}>
        <h3 className={styles.title}>Invoices</h3>
      </div>

      <div className={styles.block}>
        <h3 className={styles.title}>Billing info</h3>
      </div>
    </div>
  )
}

export default SubscriptionSettings
