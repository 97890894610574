import React from 'react'

import styles from './VerificationSettings.module.scss'

import { FileUser as UserIcon } from '../../../icons'

import InfoNotice from 'components/elements/blocks/InfoNotice'
import CustomButton from 'components/elements/buttons/CustomButton'

const VerificationSettings: React.FC = () => {
  const verificationStatus: string = 'failed'

  const handleCompleteVerification = () => {}

  const handleRequestAccess = () => {}

  const renderInfoNotice = () => {
    if (verificationStatus === 'completed') {
      return (
        <>
          <InfoNotice
            className={styles.notice}
            color={'green'}
            icon={<UserIcon />}
            title={'ID verification complete'}
          />

          <CustomButton className={styles.btn} onClick={handleRequestAccess}>
            Request domain access
          </CustomButton>

          <div className={styles.linksBlock}>
            <a
              className={styles.link}
              href="/"
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              Whitelisted domains
            </a>
            <a
              className={styles.link}
              href="/"
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              How to request custom domain access?
            </a>
          </div>
        </>
      )
    } else {
      return (
        <>
          <InfoNotice
            className={styles.notice}
            color={'red'}
            icon={<UserIcon />}
            title={'No custom domain access: ID not provided'}
          />

          <div className={styles.linksBlock}>
            <a
              className={styles.link}
              href="/"
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              Why is this necessary?
            </a>
          </div>

          <CustomButton
            className={styles.btn}
            onClick={handleCompleteVerification}
          >
            Complete ID verification
          </CustomButton>
        </>
      )
    }
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Verification</h3>

      <h5 className={styles.subTitle}>Verification status</h5>

      {renderInfoNotice()}
    </div>
  )
}

export default VerificationSettings
