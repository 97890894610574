import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 15.208a.625.625 0 1 0 0 1.25h5c.805 0 1.458-.652 1.458-1.458V5c0-.805-.653-1.458-1.458-1.458h-5a.625.625 0 1 0 0 1.25h5c.115 0 .208.093.208.208v10a.21.21 0 0 1-.208.208z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.086 11.971c.575 0 1.041-.466 1.041-1.042V9.062c0-.575-.466-1.041-1.041-1.041H8.242l-.017-.184-.045-.463a1.022 1.022 0 0 0-1.46-.823 12.6 12.6 0 0 0-3.639 2.636l-.083.087a1.044 1.044 0 0 0 0 1.444l.083.087a12.6 12.6 0 0 0 3.64 2.636c.64.307 1.39-.116 1.459-.823l.045-.463.017-.184zm-4.423-1.25a.625.625 0 0 0-.624.586q-.023.364-.058.727l-.013.134a11.3 11.3 0 0 1-2.93-2.172 11.3 11.3 0 0 1 2.93-2.172l.013.134q.035.363.058.727c.02.329.293.586.624.586h4.214v1.45z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgLogout
