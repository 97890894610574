import React, { useState } from 'react'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import {
  capitalizeWord,
  getDeepTargetResult,
  getProxyResultString,
} from 'utils/functions'

import { Check as CheckIcon, BookOpen as BookIcon } from '../../../../icons'
import ProductPricingBlock from 'components/elements/blocks/ProductPricingBlock'
import Accordion from 'components/elements/blocks/Accordion'
import CustomInput from 'components/elements/inputs/CustomInput'
import InputGroup from 'components/elements/forms/InputGroup'
import CopyButton from 'components/elements/buttons/CopyButton'
import ComboButton from 'components/elements/buttons/ComboButton'
import { IProxyConfigurationItem } from '../../ProxyConfigurationItem/ProxyConfigurationItem'
import { proxyListResultSample } from 'utils/constants'

interface IProxyListResultProps {
  selectedProxyType: any
  configurationItems: IProxyConfigurationItem[]
}

/*const resultArray2 = [
  'package-246842-country-RG-sessionid-random3_session_name_x1-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x2-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x3-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x4-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x5-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x6-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x7-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x8-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x9-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x10-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
  'package-246842-country-TR-sessionid-random3_session_name_x11-sessionlength-1200:UJWOUJZBIPb0swh2@proxy.soax.com:5000',
]*/

const ProxyListResult: React.FC<IProxyListResultProps> = (props) => {
  const { selectedProxyType, configurationItems } = props

  const [outputMask, setOutputMask] = useState(
    '{login}:{password}@{server}:{port}{login}:{password}@{server}:{port}'
  )

  const handleOutputMaskChange = (e: any) => {
    setOutputMask(e.target.value)
  }

  const getListResult = () => {
    let listResult: string[] = []

    configurationItems.forEach((item) => {
      for (let i = 1; i <= item.connectionsCount; i++) {
        const deepTargetResult = getDeepTargetResult(
          item.location.region,
          item.location.city,
          item.location.isp
        )

        listResult.push(
          getProxyResultString(
            proxyListResultSample,
            selectedProxyType.value,
            item.location.country,
            deepTargetResult,
            item.sessionType,
            item.sessionLength.value,
            `${item.sessionPrefix}_x${i}`
          )
        )
      }
    })

    return listResult
  }

  const resultArray = getListResult()

  const getProductName = () => {
    if (selectedProxyType && selectedProxyType.value) {
      const typeString = capitalizeWord(selectedProxyType.value.toString())

      return `Proxy: ${typeString} pool`
    } else {
      return ''
    }
  }

  const productName = getProductName()

  const getProductPricing = () => {
    return '6.6 per 1 GB'
  }

  const productPricing = getProductPricing()

  const handleTxtDownload = () => console.log('Txt')

  const handleCsvDownload = () => console.log('Csv')

  const resultList = resultArray.map((result, index) => (
    <li className={styles.resultListItem} key={`result-list-item_${index}`}>
      <div className={styles.resultListItemNumber}>{index + 1}</div>
      <div>{result}</div>
    </li>
  ))

  const actionOptions = [
    {
      label: 'Txt',
      onClick: () => handleTxtDownload(),
    },
    {
      label: 'CSV',
      onClick: () => handleCsvDownload(),
    },
  ]

  return (
    <>
      <div className={styles.blockHeader}>
        <span className={styles.blockTitle}>Result</span>

        <div>
          <div className={styles.checkIconWrapper}>
            <CheckIcon />
          </div>
          Ready
        </div>
      </div>

      <ProductPricingBlock
        className={styles.pricingBlock}
        product={productName}
        price={productPricing}
      />

      <Accordion title={'Edit output mask'} className={styles.maskAcc}>
        <CustomInput value={outputMask} onChange={handleOutputMaskChange} />
      </Accordion>

      <div className={styles.resultMain}>
        <InputGroup labelTop={'Proxy list'} className={styles.resultInputGroup}>
          <div className={styles.resultBlock}>
            <ol className={styles.resultList}>{resultList}</ol>
          </div>
        </InputGroup>

        <div className={styles.resultControls}>
          <CopyButton
            className={styles.resultControlBtn}
            color={'outline'}
            text={resultArray.join(' ')}
          >
            Copy text
          </CopyButton>

          <CopyButton
            className={styles.resultControlBtn}
            color={'outline'}
            text={'link'}
          >
            Copy link
          </CopyButton>

          <ComboButton
            className={styles.resultControlBtn}
            actionOptions={actionOptions}
          >
            Download
          </ComboButton>
        </div>
      </div>

      <a
        className={styles.guideLink}
        href="/"
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        <div className={styles.bookIconWrapper}>
          <BookIcon />
        </div>{' '}
        <span>Guide: how to use proxy list</span>
      </a>
    </>
  )
}

export default ProxyListResult
