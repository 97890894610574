import axios from 'axios'
// import { Env } from 'env'
// import logout from 'src/store/user'

// const SOAX_API = 'http://localhost:7071/customer/api'
const SOAX_API = process.env.REACT_APP_SOAX_API
const request = axios.create()

request.defaults.baseURL = SOAX_API
request.defaults.headers.post['Content-Type'] =
  'application/json; charset=UTF-8, multipart/form-data'
request.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'
request.defaults.withCredentials = true

export const setupOrbToken = () => {
  request.defaults.headers.common['Authorization'] =
    'Bearer ' + process.env.REACT_APP_ORB_API_KEY
}

export const setupToken = (token) => {
  request.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export const resetToken = () => {
  request.defaults.headers.common['Authorization'] = undefined
}

request.interceptors.request.use(
  (request) => request,
  (error) => {
    console.log(error)
  }
)

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      request.defaults.headers.common['Authorization'] = undefined
      localStorage.clear()
      // logout()
    }
    return Promise.reject(error.response)
  }
)

export function saveToken(result) {
  setupToken(result['token'])

  localStorage.setItem(
    'session',
    JSON.stringify({
      token: result['token'],
      expire_at: result['expire_at'],
    })
  )
}

export const post = (url, data, config = {}) => request.post(url, data, config)
export const get = (url, config = {}, disableCache = true) => {
  if (disableCache) {
    let sep = url.indexOf('?') === -1 ? '?' : '&'
    url += `${sep}_=${new Date().getTime().toString()}`
  }

  return request.get(url, config)
}

export const put = (url, data, config = {}) => request.put(url, data, config)
export const del = (url, config = {}) => request.delete(url, config)
export const patch = (url, data, config) => request.patch(url, data, config)
