export const getCookie = (cookieName: string) => {
  let cookie: any = {}

  document.cookie.split(';').forEach((el: string) => {
    let [key, value] = el.split('=')

    cookie[key.trim()] = value
  })

  return cookie[cookieName]
}

export const capitalizeWord = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

export const getNormalizedLocation = (location: string) => {
  return location.replaceAll(' ', '+')
}

export const getDeepTargetResult = (
  regionValue?: string,
  cityValue?: string,
  ispValue?: string
) => {
  let deepTargetResult: string = ''

  if (!!regionValue && regionValue !== 'any') {
    deepTargetResult =
      deepTargetResult + `-region-${getNormalizedLocation(regionValue)}`
  }

  if (!!cityValue && cityValue !== 'any') {
    deepTargetResult =
      deepTargetResult + `-city-${getNormalizedLocation(cityValue)}`
  }

  if (!!ispValue && ispValue !== 'any') {
    deepTargetResult =
      deepTargetResult + `-isp-${getNormalizedLocation(ispValue)}`
  }

  return deepTargetResult
}

export const getProxyResultString = (
  sample: string,
  proxyType: string,
  countryValue: string,
  deepTargetString: string,
  sessionLengthType: string,
  timeIntervalValue: string,
  sessionPrefix: string
) => {
  let result = sample.replace('{{proxy_type}}', proxyType)

  if (countryValue !== 'any') {
    const locationString = `-country-${countryValue}` + deepTargetString

    result = result.replace('{{location}}', locationString)
  } else {
    result = result.replace('{{location}}', '')
  }

  if (sessionLengthType === 'same') {
    result = result.replace(
      '{{session_length}}',
      `-sessionlength-${timeIntervalValue}`
    )
  } else {
    result = result.replace('{{session_length}}', '')
  }

  result = result.replace('{{session_prefix}}', sessionPrefix)

  return result
}
