import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUnlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.75 13.333a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.175 3.338a2.667 2.667 0 0 1 3.47 1.507l.063.16q.116.298.165.615l.18 1.185 1.236-.188-.18-1.186a4 4 0 0 0-.237-.881l-.062-.16a3.917 3.917 0 0 0-7.52 2.015l.026.17q.07.453.236.881l.545 1.395-.318.026a1.97 1.97 0 0 0-1.785 1.643 17.4 17.4 0 0 0 0 5.627 1.97 1.97 0 0 0 1.785 1.643l1.247.1c1.98.158 3.969.158 5.948 0l1.247-.1a1.97 1.97 0 0 0 1.785-1.643 17.4 17.4 0 0 0 0-5.627 1.97 1.97 0 0 0-1.785-1.643l-1.247-.1a37.4 37.4 0 0 0-5.575-.028l-.683-1.748a3 3 0 0 1-.164-.615l-.026-.17a2.666 2.666 0 0 1 1.65-2.878m4.7 6.685a36 36 0 0 0-5.75 0l-1.246.1a.72.72 0 0 0-.651.6 16.2 16.2 0 0 0 0 5.222.72.72 0 0 0 .65.6l1.248.099c1.913.152 3.835.152 5.748 0l1.247-.1a.72.72 0 0 0 .652-.6c.283-1.729.283-3.493 0-5.222a.72.72 0 0 0-.652-.6z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgUnlock
