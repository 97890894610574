import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.917 10a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.035 5.562a3.93 3.93 0 0 0-3.201-2.366l-.543-.057a37 37 0 0 0-8.25.057l-.36.043a3.07 3.07 0 0 0-2.678 2.64 31.2 31.2 0 0 0 0 8.242 3.07 3.07 0 0 0 2.678 2.64l.36.044c2.739.326 5.506.345 8.25.056l.543-.057a3.93 3.93 0 0 0 3.2-2.366 2.31 2.31 0 0 0 1.636-1.936 21.5 21.5 0 0 0 0-5.004 2.31 2.31 0 0 0-1.636-1.936m-3.875-1.18a35.8 35.8 0 0 0-7.972.055l-.36.043a1.82 1.82 0 0 0-1.586 1.564 30 30 0 0 0 0 7.912c.11.822.762 1.466 1.586 1.564l.36.043c2.647.316 5.321.334 7.972.055l.543-.057a2.67 2.67 0 0 0 1.77-.943c-1.256.074-2.53.04-3.772-.098a2.31 2.31 0 0 1-2.038-2.018 21.5 21.5 0 0 1 0-5.004 2.31 2.31 0 0 1 2.038-2.018 22.4 22.4 0 0 1 3.773-.098 2.67 2.67 0 0 0-1.77-.943zm2.904 2.297.002.01.005.032.166-.026.256.027c.49.055.88.442.936.921a20.3 20.3 0 0 1 0 4.714 1.06 1.06 0 0 1-.936.92q-.127.015-.256.028l-.166-.026-.005.033-.002.01a21 21 0 0 1-4.224-.044 1.06 1.06 0 0 1-.935-.921 20.3 20.3 0 0 1 0-4.714 1.06 1.06 0 0 1 .935-.92c1.391-.156 2.826-.17 4.224-.044"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgWallet
