import * as React from 'react'
import type { SVGProps } from 'react'
const SvgServer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5 6.25a.833.833 0 1 1 1.667 0A.833.833 0 0 1 5 6.25M7.5 6.25a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0M5.833 11.25a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667M8.333 11.25a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.956 2.708A2.29 2.29 0 0 0 2.664 5v2.5c0 .657.277 1.249.72 1.667a2.29 2.29 0 0 0-.72 1.666v2.5a2.29 2.29 0 0 0 2.292 2.292h4.419v1.25H4.167a.625.625 0 1 0 0 1.25h11.666a.625.625 0 0 0 0-1.25h-5.208v-1.25h4.331a2.29 2.29 0 0 0 2.292-2.292v-2.5c0-.656-.276-1.249-.719-1.666.443-.418.719-1.01.719-1.667V5a2.29 2.29 0 0 0-2.292-2.292zm0 5.834h10c.575 0 1.042-.467 1.042-1.042V5c0-.575-.466-1.042-1.042-1.042h-10c-.575 0-1.042.467-1.042 1.042v2.5c0 .575.467 1.042 1.042 1.042m0 1.25c-.575 0-1.042.466-1.042 1.041v2.5c0 .575.467 1.042 1.042 1.042h10c.575 0 1.042-.466 1.042-1.042v-2.5c0-.575-.466-1.041-1.042-1.041z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgServer
