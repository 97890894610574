import React, { useState } from 'react'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import {
  randomCountry,
  randomRegion,
  randomCity,
  randomIsp,
} from 'utils/constants'
import { Home as HomeIcon } from '../../../../icons'
import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import { IProxyConfigurationItem } from '../../ProxyConfigurationItem/ProxyConfigurationItem'
import CustomButton from 'components/elements/buttons/CustomButton'
import ProxyConfigurationForm from '../../ProxyConfigurationForm'
import ProxyConfigurationItem from '../../ProxyConfigurationItem'

interface IProxyListConfigurationProps {
  selectedProxyType: any
  configurationItems: IProxyConfigurationItem[]
  onProxyTypeChange: (option: ISelectOption) => void
}

const proxyTypesOptions = [
  {
    label: 'Residential proxy',
    value: 'residential',
    icon: <HomeIcon />,
  },
  {
    label: 'Mobile proxy',
    value: 'mobile',
    icon: <HomeIcon />,
  },
]

const ProxyListConfiguration: React.FC<IProxyListConfigurationProps> = (
  props
) => {
  const { selectedProxyType, configurationItems, onProxyTypeChange } = props

  const [isFormShown, toggleFormVisibility] = useState(false)

  const [selectedTimeInterval, setTimeInterval] =
    useState<ISelectOption | null>({
      label: '20 min',
      value: '1200',
    })
  const [isBindIPActive, toggleBindIPActive] = useState(false)
  const [activeSessionLengthType, setSessionLengthType] = useState('same')
  const [sessionPrefix, setSessionPrefix] = useState('random3_session_name')
  const [connectionsAmount, setConnectionsAmount] = useState('123')
  const [selectedCountry, setCountry] = useState<ISelectOption>(randomCountry)
  const [selectedRegion, setRegion] = useState<ISelectOption>(randomRegion)
  const [selectedCity, setCity] = useState<ISelectOption>(randomCity)
  const [selectedIsp, setIsp] = useState<ISelectOption>(randomIsp)

  const availableConnectionsAmount = '49231'

  const handleProxyTypeChange = (option: any) => {
    onProxyTypeChange(option)
  }

  const handleCountryChange = (option: any) => {
    setCountry(option)
  }

  const handleRegionChange = (option: any) => {
    setRegion(option)
  }

  const handleCityChange = (option: any) => {
    setCity(option)
  }

  const handleIspChange = (option: any) => {
    setIsp(option)
  }

  const handleTimeIntervalChange = (option: any) => {
    setTimeInterval(option)
  }

  const handleBindIP = () => {
    toggleBindIPActive(!isBindIPActive)
  }

  const handleActiveSessionLengthChange = (e: any) => {
    setSessionLengthType(e.target.value)
  }

  const handleSessionPrefixChange = (e: any) => {
    setSessionPrefix(e.target.value)
  }

  const handleConnectionsAmountChange = (e: any) => {
    setConnectionsAmount(e.target.value)
  }

  const handleFormShow = () => {
    toggleFormVisibility(true)
  }

  const handleSaveConfiguration = () => {
    toggleFormVisibility(false)
  }

  const handleConfigurationItemEditClick = (id: number) => {
    console.log(id)
  }

  const handleConfigurationItemDeleteClick = (id: number) => {
    console.log(id)
  }

  const configurationItemsList = configurationItems.map((item) => (
    <ProxyConfigurationItem
      item={item}
      className={styles.configurationItem}
      key={`configuration-item_${item.id}`}
      onEditClick={() => handleConfigurationItemEditClick(item.id)}
      onDeleteClick={() => handleConfigurationItemDeleteClick(item.id)}
    />
  ))

  return (
    <>
      <div className={styles.blockHeader}>
        <span className={styles.blockTitle}>Proxy list configuration</span>
      </div>

      <InputGroup
        className={styles.proxyTypeGroup}
        labelTop={'Proxy pool'}
        description={'Select pool here'}
      >
        <CustomSelect
          onChange={handleProxyTypeChange}
          value={selectedProxyType}
          name={'proxy-type-select'}
          options={proxyTypesOptions}
        />
      </InputGroup>

      {!!configurationItemsList.length && (
        <div className={styles.configurationsList}>
          {configurationItemsList}
        </div>
      )}

      {isFormShown ? (
        <div className={styles.formContainer}>
          <div className={styles.formWrapper}>
            <ProxyConfigurationForm
              isConnectionsShown={true}
              selectedCountry={selectedCountry}
              selectedRegion={selectedRegion}
              selectedCity={selectedCity}
              selectedIsp={selectedIsp}
              activeSessionLengthType={activeSessionLengthType}
              selectedTimeInterval={selectedTimeInterval}
              isBindIPActive={isBindIPActive}
              sessionPrefix={sessionPrefix}
              connectionsAmount={connectionsAmount}
              availableConnectionsAmount={availableConnectionsAmount}
              onCountryChange={handleCountryChange}
              onRegionChange={handleRegionChange}
              onCityChange={handleCityChange}
              onIspChange={handleIspChange}
              onActiveSessionLengthChange={handleActiveSessionLengthChange}
              onTimeIntervalChange={handleTimeIntervalChange}
              onBindIp={handleBindIP}
              onSessionPrefixChange={handleSessionPrefixChange}
              onConnectionsAmountChange={handleConnectionsAmountChange}
            />
          </div>

          <CustomButton
            wide
            color={'outline'}
            onClick={handleSaveConfiguration}
          >
            Save and add
          </CustomButton>
        </div>
      ) : (
        <CustomButton wide color={'outline'} onClick={handleFormShow}>
          Add more connections to the list
        </CustomButton>
      )}
    </>
  )
}

export default ProxyListConfiguration
