import { MutableRefObject, useEffect } from 'react'

export const useOutsideClick = (
  ref: MutableRefObject<null>,
  callback: { (): void; (): void }
) => {
  const handleClick = (e: { target: any }) => {
    if (!(ref.current! as any).contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}
