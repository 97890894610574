import React from 'react'

import { LoadingXl as SpinnerIcon } from '../../../icons'

import styles from './Loader.module.scss'

interface ISpinnerProps {
  classname?: string
}

const Loader: React.FC<ISpinnerProps> = (props) => {
  const { classname } = props

  return (
    <div
      className={`
        ${styles.container}
        ${classname ? classname : ''}
      `}
    >
      <SpinnerIcon className={styles.spinner} />
    </div>
  )
}

export default Loader
