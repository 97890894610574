import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChartPie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.375 4.824a6.042 6.042 0 1 0 6.635 6.634H10a.625.625 0 0 1-.625-.625zm-6.667 6.01A7.29 7.29 0 0 1 10 3.541c.345 0 .625.28.625.625v6.041h6.042c.345 0 .625.28.625.625a7.292 7.292 0 1 1-14.584 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.917 3.895v4.022h4.022a5.43 5.43 0 0 0-4.022-4.022m-.419-1.344a6.67 6.67 0 0 1 5.784 5.784c.057.457-.322.832-.782.832h-5.417a.417.417 0 0 1-.416-.417V3.333c0-.46.375-.839.831-.782"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgChartPie
