import React from 'react'

import styles from './TitledCard.module.scss'

interface ITitledCardProps {
  title: string
  children: React.ReactNode
}

const TitledCard: React.FC<ITitledCardProps> = (props) => {
  const { title, children } = props

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  )
}

export default TitledCard
