export enum PricingActionsTypes {
  PRICING_GET_SUBSCRIPTIONS = 'PRICING_GET_SUBSCRIPTIONS',
  PRICING_GET_SUBSCRIPTIONS_SUCCESS = 'PRICING_GET_SUBSCRIPTIONS_SUCCESS',
  PRICING_GET_SUBSCRIPTIONS_FAIL = 'PRICING_GET_SUBSCRIPTIONS_FAIL',

  PRICING_GET_PRICE_METRIC = 'PRICING_GET_PRICE_METRIC',
  PRICING_GET_PRICE_METRIC_SUCCESS = 'PRICING_GET_PRICE_METRIC_SUCCESS',
  PRICING_GET_PRICE_METRIC_FAIL = 'PRICING_GET_PRICE_METRIC_FAIL',

  PRICING_GET_PRICES = 'PRICING_GET_PRICES',
  PRICING_GET_PRICES_SUCCESS = 'PRICING_GET_PRICES_SUCCESS',
  PRICING_GET_PRICES_FAIL = 'PRICING_GET_PRICES_FAIL',
}
