import React from 'react'
import styles from './PlansComparisonTable.module.scss'
import { CaretDown } from '../../../icons'

interface ISubColumnProps {
  title?: string
  items: { label: string; productId: string }[]
  state: boolean
  plan?: any
  handler: () => void
}

const SubColumn: React.FC<ISubColumnProps> = (props) => {
  const { title, items, state, plan, handler } = props

  const getLowerPrice = () => {
    return 'from $2.20'
  }

  const itemsList = items.map((item, index) => (
    <div className={styles.cell} key={`subcolumn-item_${item.productId}`}>
      {plan ? '2.2' : item.label}
    </div>
  ))

  return (
    <div className={styles.subColumnWrapper}>
      <div
        className={`
              ${styles.cell}
              ${styles.toggle}
              ${state ? styles.active : ''}
            `}
        onClick={handler}
      >
        {!!title ? (
          <>
            {title}
            <div
              className={`
                ${styles.toggleIconWrapper}
                ${state ? styles.active : ''}
              `}
            >
              <CaretDown />
            </div>
          </>
        ) : (
          getLowerPrice()
        )}
      </div>

      <div
        className={`
              ${styles.subColumn}
              ${styles.rowLabels}
              ${state ? styles.active : ''}
            `}
      >
        {itemsList}
      </div>
    </div>
  )
}

export default SubColumn
