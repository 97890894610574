import React from 'react'

import styles from './WhitelistTable.module.scss'

import { Trash as TrashIcon /*, Edit as EditIcon*/ } from '../../../../icons'
import CustomButton from 'components/elements/buttons/CustomButton'

interface IWhitelistItem {
  ip: string
  comment?: string
}

interface IWhitelistTable {
  items: IWhitelistItem[]
}

const WhitelistTable: React.FC<IWhitelistTable> = (props) => {
  const { items } = props

  const handleDeleteItem = (item: IWhitelistItem) => {
    console.log(item)
  }

  /*const handleEditItem = (item: IWhitelistItem) => {
    console.log(item)
  }*/

  const itemsList = items.map((item, index) => (
    <div className={styles.tr} key={`whitelist-table-item_${index}`}>
      <div className={styles.td}>{index + 1}</div>
      <div className={styles.td}>{item.ip}</div>
      <div className={styles.td}>{item.comment || ''}</div>
      <div className={styles.td}>
        <CustomButton
          color={'ghost'}
          icon={<TrashIcon />}
          onClick={() => handleDeleteItem(item)}
        />
        {/*<CustomButton
          color={'ghost'}
          icon={<EditIcon />}
          onClick={() => handleEditItem(item)}
        />*/}
      </div>
    </div>
  ))

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.th}>#</div>
        <div className={styles.th}>IP ADDRESS</div>
        <div className={styles.th}>Label</div>
        <div className={styles.th} />
      </div>

      {itemsList}
    </div>
  )
}

export default WhitelistTable
