import React, { ReactNode } from 'react'

import styles from './InfoNotice.module.scss'

interface IInfoNotice {
  color: 'red' | 'blue' | 'gray' | 'green'
  title?: string
  icon: ReactNode
  className?: string
  children?: any
}

const InfoNotice: React.FC<IInfoNotice> = (props) => {
  const { icon, color, className, title, children } = props

  return (
    <div
      className={`
        ${styles.container}
        ${styles[color]}
        ${className || ''}
      `}
    >
      {!!icon && <div className={styles.iconWrapper}>{icon}</div>}

      <div>
        {!!title && <span className={styles.title}>{title}</span>}
        {!!title && children && <div className={styles.divider} />}
        {!!children && <span className={styles.content}>{children}</span>}
      </div>
    </div>
  )
}

export default InfoNotice
