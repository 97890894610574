import React, { useState } from 'react'

import styles from '../ProxyContent/ProxyContent.module.scss'

import {
  randomCountry,
  randomRegion,
  randomCity,
  randomIsp,
  curlResultSample,
  credResultSample,
  urlResultSample,
} from 'utils/constants'
import { getDeepTargetResult, getProxyResultString } from 'utils/functions'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import ProxyQuickConfiguration from './ProxyQuickConfiguration'
import ProxyQuickResult from './ProxyQuickResult'
import { Home as HomeIcon } from '../../../icons'

const proxyTypesOptions = [
  {
    label: 'Residential proxy',
    value: 'residential',
    icon: <HomeIcon />,
  },
  {
    label: 'Mobile proxy',
    value: 'mobile',
    icon: <HomeIcon />,
  },
  {
    label: 'Datacenter',
    value: 'dc',
    icon: <HomeIcon />,
  },
  {
    label: 'ISP USA',
    value: 'isp',
    icon: <HomeIcon />,
  },
]

const ProxyQuickConnect: React.FC = () => {
  const [selectedProxyType, setProxyType] = useState<ISelectOption>(
    proxyTypesOptions[0]
  )
  const [selectedCountry, setCountry] = useState<ISelectOption>(randomCountry)
  const [selectedRegion, setRegion] = useState<ISelectOption>(randomRegion)
  const [selectedCity, setCity] = useState<ISelectOption>(randomCity)
  const [selectedIsp, setIsp] = useState<ISelectOption>(randomIsp)
  const [selectedTimeInterval, setTimeInterval] = useState<ISelectOption>({
    label: '20 min',
    value: '1200',
  })
  const [isBindIPActive, toggleBindIPActive] = useState(false)
  const [activeSessionLengthType, setSessionLengthType] = useState<
    'same' | 'new'
  >('same')
  const [sessionPrefix, setSessionPrefix] = useState('random3_session_name')

  const deepTargetResult = getDeepTargetResult(
    selectedRegion.value,
    selectedCity.value,
    selectedIsp.value
  )

  const urlResultString = getProxyResultString(
    urlResultSample,
    selectedProxyType.value,
    selectedCountry.value,
    deepTargetResult,
    activeSessionLengthType,
    selectedTimeInterval.value,
    sessionPrefix
  )
  const credResultString = getProxyResultString(
    credResultSample,
    selectedProxyType.value,
    selectedCountry.value,
    deepTargetResult,
    activeSessionLengthType,
    selectedTimeInterval.value,
    sessionPrefix
  )
  const curlResultString = getProxyResultString(
    curlResultSample,
    selectedProxyType.value,
    selectedCountry.value,
    deepTargetResult,
    activeSessionLengthType,
    selectedTimeInterval.value,
    sessionPrefix
  )

  const handleProxyTypeChange = (option: any) => {
    setProxyType(option)
  }

  const handleCountryChange = (option: any) => {
    setCountry(option)
  }

  const handleRegionChange = (option: any) => {
    setRegion(option)
  }

  const handleCityChange = (option: any) => {
    setCity(option)
  }

  const handleIspChange = (option: any) => {
    setIsp(option)
  }

  const handleTimeIntervalChange = (option: any) => {
    setTimeInterval(option)
  }

  const handleBindIP = () => {
    toggleBindIPActive(!isBindIPActive)
  }

  const handleActiveSessionLengthChange = (e: any) => {
    setSessionLengthType(e.target.value)
  }

  const handleSessionPrefixChange = (e: any) => {
    setSessionPrefix(e.target.value)
  }

  return (
    <div className={styles.container}>
      <div
        className={`
          ${styles.contentBlock}
          ${styles.leftSide}
        `}
      >
        <ProxyQuickConfiguration
          selectedProxyType={selectedProxyType}
          selectedTimeInterval={selectedTimeInterval}
          selectedCountry={selectedCountry}
          selectedRegion={selectedRegion}
          selectedCity={selectedCity}
          selectedIsp={selectedIsp}
          isBindIPActive={isBindIPActive}
          activeSessionLengthType={activeSessionLengthType}
          sessionPrefix={sessionPrefix}
          proxyTypesOptions={proxyTypesOptions}
          onProxyTypeChange={handleProxyTypeChange}
          onTimeIntervalChange={handleTimeIntervalChange}
          onBindIp={handleBindIP}
          onActiveSessionLengthChange={handleActiveSessionLengthChange}
          onSessionPrefixChange={handleSessionPrefixChange}
          onCountryChange={handleCountryChange}
          onRegionChange={handleRegionChange}
          onCityChange={handleCityChange}
          onIspChange={handleIspChange}
        />
      </div>

      <div
        className={`
          ${styles.contentBlock}
          ${styles.rightSide}
        `}
      >
        <ProxyQuickResult
          selectedProxyType={selectedProxyType}
          urlResultString={urlResultString}
          credResultString={credResultString}
          curlResultString={curlResultString}
        />
      </div>
    </div>
  )
}

export default ProxyQuickConnect
