import React, { useCallback, useMemo, useState, useEffect } from 'react'

import styles from './ScrapingBuilder.module.scss'

import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'
import ProductPricingBlock from 'components/elements/blocks/ProductPricingBlock'
import TabsList from 'components/elements/tabs/TabsList'
import CopyButton from 'components/elements/buttons/CopyButton'

import {
  JSONData,
  getApiTypes,
  getSelectOptions,
  isLastLevel,
} from 'json-parser/json-parser'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'

const ScrapingBuilder: React.FC = () => {
  const [selectedApiType, setApiType] = useState<ISelectOption | null>({
    label: 'SERP',
    value: 'SERP',
  })
  const [selectedFirstLevelOption, setFirstLevelOption] =
    useState<ISelectOption | null>({
      label: 'Google Search API Basic',
      value: 'Google Search API Basic',
    })
  const [selectedSecondLevelOption, setSecondLevelOption] =
    useState<ISelectOption | null>({
      label: 'Google Search: Request',
      value: 'Google Search: Request',
    })
  const [selectedThirdLevelOption, setThirdLevelOption] =
    useState<ISelectOption | null>(null)
  const [selectedFourthLevelOption, setFourthLevelOption] =
    useState<ISelectOption | null>(null)
  const [selectedFifthLevelOption, setFifthLevelOption] =
    useState<ISelectOption | null>(null)
  const [allowedParams, setParams] = useState<object>({})

  const [selectedCommandTypeIndex, setCommandTypeIndex] = useState(0)

  const apiTypes = getApiTypes()

  const commandTypes = [
    {
      label: 'cURL',
      value: 'Sample Curl Code',
    },
    {
      label: 'Python',
      value: 'Sample Python Code',
    },
    /*{
      label: 'Node.JS',
      value: 'node',
    },
    {
      label: 'PHP',
      value: 'php',
    },
    {
      label: 'Ruby',
      value: 'ruby',
    },*/
  ]

  const selectedOptions = useMemo(() => {
    return [
      selectedApiType,
      selectedFirstLevelOption,
      selectedSecondLevelOption,
      selectedThirdLevelOption,
      selectedFourthLevelOption,
      selectedFifthLevelOption,
    ]
  }, [
    selectedApiType,
    selectedFirstLevelOption,
    selectedSecondLevelOption,
    selectedThirdLevelOption,
    selectedFourthLevelOption,
    selectedFifthLevelOption,
  ])

  const getProductName = () => {
    let names: string[] = []

    selectedOptions.forEach((option) => {
      if (option?.label) {
        names.push(option.label)
      }
    })

    return `Scrapping API: ${names.join(' > ')}`
  }

  const productName = getProductName()

  const getProductPricing = () => {
    return '3.6 per 1000 req.'
  }

  const productPricing = getProductPricing()

  const handleApiTypeChange = (option: any) => {
    setApiType(option)

    setFirstLevelOption(null)
    setSecondLevelOption(null)
    setThirdLevelOption(null)
    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleFirstSelectChange = (option: any) => {
    setFirstLevelOption(option)

    setSecondLevelOption(null)
    setThirdLevelOption(null)
    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleSecondSelectChange = (option: any) => {
    setSecondLevelOption(option)

    setThirdLevelOption(null)
    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleThirdSelectChange = (option: any) => {
    setThirdLevelOption(option)

    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleFourthSelectChange = (option: any) => {
    setFourthLevelOption(option)

    setFifthLevelOption(null)
    setParams({})
  }

  const handleFifthSelectChange = (option: any) => {
    setFifthLevelOption(option)
    setParams({})
  }

  /*const selectsHandlers = useMemo(() => {
    return [
      handleApiTypeChange,
      handleFirstSelectChange,
      handleSecondSelectChange,
      handleThirdSelectChange,
      handleFourthSelectChange,
      handleFifthSelectChange,
    ]
  }, [
    handleApiTypeChange,
    handleFirstSelectChange,
    handleSecondSelectChange,
    handleThirdSelectChange,
    handleFourthSelectChange,
    handleFifthSelectChange,
  ])

  const selectsNames = useMemo(() => {
    return [
      'api-type-select',
      'first-level-select',
      'second-level-select',
      'third-level-select',
      'fourth-level-select',
      'fifth-level-select',
    ]
  }, [])*/

  const getLastLevelData = useCallback(() => {
    let data = JSONData

    for (let i = selectedOptions.length - 1; i >= 0; i--) {
      if (selectedOptions[i]) {
        for (let k = 0; k <= i; k++) {
          // @ts-ignore
          data = data[selectedOptions[k].value]
        }

        break
      }
    }

    return data
  }, [selectedOptions])

  const lastLevelData = useMemo(() => getLastLevelData(), [getLastLevelData])

  const handleCodeTypeChange = (index: number) => {
    setCommandTypeIndex(index)
  }

  useEffect(() => {
    if (
      // @ts-ignore
      lastLevelData?.Params?.length &&
      !Object.keys(allowedParams).length
    ) {
      let levelParams = {}

      // @ts-ignore
      lastLevelData.Params.forEach((param: any) => {
        levelParams = {
          ...levelParams,
          [param.value]: param.default_value,
        }
      })

      setParams(levelParams)
    }
  }, [lastLevelData, allowedParams])

  const handleParamChange = useCallback(
    (e: any) => {
      setParams({
        ...allowedParams,
        [e.target.name]: e.target.value,
      })
    },
    [allowedParams]
  )

  const getCurrentCodeExample = () => {
    // @ts-ignore
    if (lastLevelData.examples) {
      const activeCommandTypeValue =
        commandTypes[selectedCommandTypeIndex].value

      // @ts-ignore
      return lastLevelData.examples[activeCommandTypeValue]
    } else {
      return null
    }
  }

  const currentCodeExample = getCurrentCodeExample()

  const getCodeString = () => {
    if (currentCodeExample) {
      if (!!Object.keys(allowedParams).length) {
        let codeString = currentCodeExample

        Object.keys(allowedParams).forEach((key) => {
          // @ts-ignore
          codeString = codeString.replace(key, allowedParams[key])
        })

        return codeString
      } else {
        return currentCodeExample
      }
    } else {
      return 'Please select options above'
    }
  }

  const codeString = getCodeString()

  const renderParamsInputs = useCallback(() => {
    // @ts-ignore
    if (!!lastLevelData.Params && !!lastLevelData.Params.length) {
      // @ts-ignore
      return lastLevelData.Params.map((param: any, index: number) => {
        return (
          <InputGroup
            key={`param-input__${index}`}
            className={styles.inputGroup}
            labelTop={param.display_name}
            description={param.description}
            name={param.key}
          >
            <CustomInput
              className={styles.input}
              // @ts-ignore
              value={allowedParams[param.value]}
              name={param.value}
              onChange={handleParamChange}
            />
          </InputGroup>
        )
      })
    } else {
      return null
    }
    // @ts-ignore
  }, [lastLevelData.Params, allowedParams, handleParamChange])

  const renderSelect = (
    levelData: any,
    previousLevelOption: ISelectOption | null,
    selectValue: ISelectOption | null,
    changeHandler: (option: any) => void,
    selectName: string
  ) => {
    // @ts-ignore
    if (previousLevelOption && !isLastLevel(levelData)) {
      return (
        <InputGroup className={styles.inputGroup}>
          <CustomSelect
            onChange={changeHandler}
            value={selectValue}
            name={selectName}
            // @ts-ignore
            options={getSelectOptions(levelData)}
            // error={'Keke'}
          />
        </InputGroup>
      )
    } else {
      return null
    }
  }

  const renderFirstLevelSelect = () => {
    if (!selectedApiType) return null
    // @ts-ignore
    const levelData = JSONData[selectedApiType.value]

    return renderSelect(
      levelData,
      selectedApiType,
      selectedFirstLevelOption,
      handleFirstSelectChange,
      'first-level-select'
    )
  }

  const renderSecondLevelSelect = () => {
    if (!selectedApiType || !selectedFirstLevelOption) return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value]

    return renderSelect(
      levelData,
      selectedFirstLevelOption,
      selectedSecondLevelOption,
      handleSecondSelectChange,
      'second-level-select'
    )
  }

  const renderThirdLevelSelect = () => {
    if (
      !selectedApiType ||
      !selectedFirstLevelOption ||
      !selectedSecondLevelOption
    )
      return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value][
        selectedSecondLevelOption.value
      ]

    return renderSelect(
      levelData,
      selectedSecondLevelOption,
      selectedThirdLevelOption,
      handleThirdSelectChange,
      'third-level-select'
    )
  }

  const renderFourthLevelSelect = () => {
    if (
      !selectedApiType ||
      !selectedFirstLevelOption ||
      !selectedSecondLevelOption ||
      !selectedThirdLevelOption
    )
      return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value][
        selectedSecondLevelOption.value
      ][selectedThirdLevelOption.value]

    return renderSelect(
      levelData,
      selectedThirdLevelOption,
      selectedFourthLevelOption,
      handleFourthSelectChange,
      'fourth-level-select'
    )
  }

  const renderFifthLevelSelect = () => {
    if (
      !selectedApiType ||
      !selectedFirstLevelOption ||
      !selectedSecondLevelOption ||
      !selectedThirdLevelOption ||
      !selectedFourthLevelOption
    )
      return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value][
        selectedSecondLevelOption.value
      ][selectedThirdLevelOption.value][selectedFourthLevelOption.value]

    return renderSelect(
      levelData,
      selectedFourthLevelOption,
      selectedFifthLevelOption,
      handleFifthSelectChange,
      'fifth-level-select'
    )
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Make your request</h3>

      <div className={styles.content}>
        <div className={styles.form}>
          <InputGroup className={styles.inputGroup} labelTop={'API type'}>
            <CustomSelect
              onChange={handleApiTypeChange}
              value={selectedApiType}
              name={'api-type-select'}
              options={apiTypes}
              // error={'Keke'}
            />
          </InputGroup>

          {renderFirstLevelSelect()}
          {renderSecondLevelSelect()}
          {renderThirdLevelSelect()}
          {renderFourthLevelSelect()}
          {renderFifthLevelSelect()}

          <div className={styles.formRow}>{renderParamsInputs()}</div>
        </div>

        <ProductPricingBlock
          className={styles.pricingBlock}
          product={productName}
          price={productPricing}
        />

        <div className={styles.builder}>
          <div className={styles.builderHeader}>
            <TabsList
              items={commandTypes}
              onChange={handleCodeTypeChange}
              activeTabIndex={selectedCommandTypeIndex}
            />

            <CopyButton text={codeString} size={'s'} />
          </div>

          <div className={styles.builderContent}>
            <span>$</span> {codeString}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScrapingBuilder
