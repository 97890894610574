import * as React from 'react'
import type { SVGProps } from 'react'
const SvgXml = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.509 3.93a.75.75 0 0 1 .88-.582l.03.006c.41.083.673.487.585.9L9.484 16.07a.75.75 0 0 1-.88.582l-.029-.006a.755.755 0 0 1-.586-.9zm4.648 6.604a.76.76 0 0 0 0-1.069l-1.94-1.955a.76.76 0 0 1-.22-.535c0-.673.807-1.01 1.28-.535l3.003 3.025a.76.76 0 0 1 0 1.069l-3.004 3.02c-.472.476-1.28.136-1.28-.536 0-.2.08-.394.22-.535zm-12.437 0a.76.76 0 0 1 0-1.068l2.997-3.024c.472-.476 1.28-.14 1.28.534 0 .2-.08.392-.22.533L4.836 9.465a.76.76 0 0 0 0 1.069l1.94 1.949c.142.141.22.334.22.534 0 .674-.807 1.01-1.28.535z"
    />
  </svg>
)
export default SvgXml
