import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInfoRect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 8.958c.345 0 .625.28.625.625v4.167a.625.625 0 1 1-1.25 0V9.583c0-.345.28-.625.625-.625M10 7.5a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.097 3.14a35.4 35.4 0 0 1 7.806 0 3.32 3.32 0 0 1 2.93 2.9c.308 2.631.308 5.289 0 7.92a3.32 3.32 0 0 1-2.93 2.9 35.4 35.4 0 0 1-7.806 0 3.32 3.32 0 0 1-2.93-2.9 34 34 0 0 1 0-7.92 3.32 3.32 0 0 1 2.93-2.9m7.667 1.243a34.2 34.2 0 0 0-7.528 0A2.07 2.07 0 0 0 4.41 6.186a32.8 32.8 0 0 0 0 7.629c.11.943.873 1.696 1.827 1.802 2.48.277 5.047.277 7.528 0a2.07 2.07 0 0 0 1.827-1.803 32.8 32.8 0 0 0 0-7.628 2.07 2.07 0 0 0-1.827-1.803"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgInfoRect
