import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import styles from './CopyBlock.module.scss'

import { Copy as CopyIcon } from '../../../icons'

interface ICopyBlockProps {
  text: string
  className?: string
}

const CopyBlock: React.FC<ICopyBlockProps> = (props) => {
  const { text, className } = props

  const [copiedVar, setCopiedVar] = useState<boolean | null>(null)

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setCopiedVar(true)
          setTimeout(() => setCopiedVar(false), 300)
        }}
      >
        <div className={styles.content}>{text}</div>
      </CopyToClipboard>

      <div className={styles.iconWrapper}>
        <CopyIcon />
      </div>

      <span
        className={`
          ${styles.tip}
          ${copiedVar ? styles.active : ''}
        `}
      >
        Copied
      </span>
    </div>
  )
}

export default CopyBlock
