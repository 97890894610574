import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCheckedBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.236 4.383A2.07 2.07 0 0 0 4.41 6.186a32.8 32.8 0 0 0 0 7.628c.11.944.874 1.697 1.827 1.803 2.48.277 5.047.277 7.528 0a2.07 2.07 0 0 0 1.827-1.803c.213-1.82.273-3.655.18-5.483a.17.17 0 0 1 .049-.127l.866-.866c.1-.1.27-.037.282.104.163 2.17.118 4.353-.135 6.518a3.32 3.32 0 0 1-2.93 2.9 35.4 35.4 0 0 1-7.806 0 3.32 3.32 0 0 1-2.93-2.9 34 34 0 0 1 0-7.92 3.32 3.32 0 0 1 2.93-2.9 35.4 35.4 0 0 1 7.806 0c.536.06 1.036.248 1.466.533a.16.16 0 0 1 .021.247l-.669.669a.18.18 0 0 1-.208.027 2.1 2.1 0 0 0-.749-.233 34.2 34.2 0 0 0-7.528 0"
    />
    <path
      fill="currentColor"
      d="M17.525 5.025a.625.625 0 1 0-.883-.884L9.583 11.2 7.525 9.14a.625.625 0 1 0-.884.884l2.5 2.5c.245.244.64.244.884 0z"
    />
  </svg>
)
export default SvgCheckedBox
