import { put, takeLatest /*, call*/ } from 'redux-saga/effects'
import { Action } from 'redux'
import * as error from 'utils/error-handler'
import { PricingActionsTypes } from './actions'
// import * as api from './api'

import { IPricingAction } from 'models/pricing'

const getPrices = function* (action: IPricingAction) {
  try {
    // const { data } = yield call(api.getUserSubscriptions)
    // const data = fetch('https://jsonplaceholder.typicode.com/users').then(response => response.json())
  } catch (e) {
    const handledAction: Action = yield error.handle(
      { type: PricingActionsTypes.PRICING_GET_PRICES_FAIL },
      e
    )
    yield put(handledAction)
  }
}

function* pricingSaga() {
  yield takeLatest(PricingActionsTypes.PRICING_GET_PRICES, getPrices)
}

export default pricingSaga
