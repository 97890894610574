import React, { useState } from 'react'

import styles from './PricingContent.module.scss'

import {
  Timer as TimerIcon,
  Present as PresentIcon,
  CheckedBox as CheckIcon,
} from 'components/icons'

import TabsList from 'components/elements/tabs/TabsList'
import CustomToggle from 'components/elements/inputs/CustomToggle'
import PromoBlock from 'components/elements/blocks/PromoBlock'
import PlansList from 'components/modules/pricing/PlansList/PlansList'
import PlansComparisonTable from 'components/modules/pricing/PlansComparisonTable'

const plans = [
  {
    id: 441,
    name: 'DC 75',
    amount: '249',
    type: 'general',
    prices: {
      api: '2.2',
      proxy: '3.0',
      unblocker: '5.0',
    },
    trial_price: 1.99,
  },
  {
    id: 442,
    name: 'DC 75',
    amount: '249',
    type: 'general',
    prices: {
      api: '2.2',
      proxy: '3.0',
      unblocker: '5.0',
    },
    trial_price: 1.99,
  },
  {
    id: 443,
    name: 'DC 75',
    amount: '249',
    type: 'general',
    prices: {
      api: '2.2',
      proxy: '3.0',
      unblocker: '5.0',
    },
    trial_price: 1.99,
  },
  {
    id: 444,
    name: 'DC 75',
    amount: '249',
    type: 'general',
    prices: {
      api: '2.2',
      proxy: '3.0',
      unblocker: '5.0',
    },
    trial_price: 1.99,
  },
  {
    id: 445,
    name: 'Enterprise 1',
    amount: '249',
    type: 'enterprise',
    prices: {
      api: '2.2',
      proxy: '3.0',
      unblocker: '5.0',
    },
    trial_price: 1.99,
  },
  {
    id: 446,
    name: 'Enterprise 2',
    amount: '249',
    type: 'enterprise',
    prices: {
      api: '2.2',
      proxy: '3.0',
      unblocker: '5.0',
    },
    trial_price: 1.99,
  },
  {
    id: 447,
    name: 'Enterprise 3',
    amount: '249',
    type: 'enterprise',
    prices: {
      api: '2.2',
      proxy: '3.0',
      unblocker: '5.0',
    },
    trial_price: 1.99,
  },
]

const trialPromoItems = [
  {
    label: '3 days',
    icon: <TimerIcon />,
  },
  {
    label: '$5 on your balance',
    icon: <PresentIcon />,
  },
  {
    label: 'All products',
    icon: <CheckIcon />,
  },
]

const tabItems = [
  {
    label: 'Monthly',
  },
  {
    label: 'Annually',
    note: 'save 30%',
  },
]

const PricingContent: React.FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isEnterpriseActive, toggleEnterprise] = useState(false)

  const isTrialAvailable = true

  const handleTabChange = (index: number) => setActiveTabIndex(index)

  const handleEnterpriseToggle = () => toggleEnterprise(!isEnterpriseActive)

  const getActualPlans = () => {
    if (isEnterpriseActive) {
      return plans.filter((product) => product.type === 'enterprise')
    } else {
      return plans.filter((product) => product.type === 'general')
    }
  }

  const actualPlans = getActualPlans()

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <TabsList
          items={tabItems}
          activeTabIndex={activeTabIndex}
          size={'l'}
          color={'blue'}
          bordered
          onChange={handleTabChange}
        />

        <CustomToggle
          id={'enterprise-checkbox'}
          labelLeft={'Enterprise'}
          checked={isEnterpriseActive}
          onChange={handleEnterpriseToggle}
        />
      </div>

      <PlansList
        className={styles.plansList}
        plansList={actualPlans}
        isTrialAvailable={isTrialAvailable}
        isEnterpriseActive={isEnterpriseActive}
      />

      {isTrialAvailable && (
        <PromoBlock
          title={'Try before subscribe'}
          image={`${process.env.PUBLIC_URL}/ball.png`}
          items={trialPromoItems}
          className={styles.promoBlock}
        />
      )}

      <PlansComparisonTable
        plans={actualPlans}
        isTrialAvailable={isTrialAvailable}
      />
    </div>
  )
}

export default PricingContent
