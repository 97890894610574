import { PricingActionsTypes } from 'store/pricing/actions'
import { IPricingAction, IPricingState } from 'models/pricing'

const initialState: IPricingState = {
  fetching: false,
}

const pricingReducer = (
  state: IPricingState = initialState,
  action: IPricingAction
) => {
  switch (action.type) {
    case PricingActionsTypes.PRICING_GET_PRICES:
      return {
        ...state,
        fetching: true,
      }

    default:
      return state
  }
}

export default pricingReducer
