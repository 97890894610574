import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCaretRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.573 2.913a.8.8 0 0 1 1.157 0l4.364 4.491a.86.86 0 0 1 0 1.191L6.73 13.087a.8.8 0 0 1-1.157 0 .86.86 0 0 1 0-1.191L9.358 8 5.573 4.104a.86.86 0 0 1 0-1.19"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCaretRight
