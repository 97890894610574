import React, { useState, useCallback } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

/*import checkAuth from 'utils/guard'*/
/*import { logAmplitudeEvent } from 'utils/analytics'*/

import {
  ChartPie as ChartIcon,
  Home as HomeIcon,
  Xml as XmlIcon,
  Server as ServerIcon,
  // Unlock as UnlockIcon,
  Chat as ChatIcon,
  InfoRect as InfoIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
} from 'components/icons'

import MainLayout from 'components/layouts/MainLayout/MainLayout'

import HomePage from 'pages/HomePage'
import ScrapingPage from 'pages/products/ScrapingPage'
import ProxyPage from 'pages/products/ProxyPage'
import UnblockerPage from 'pages/products/UnblockerPage/UnblockerPage'
import PricingPage from 'pages/PricingPage'
import SettingsPage from 'pages/SettingsPage'
import StatisticsPage from 'pages/StatisticsPage'

const RoutesComponent = () => {
  const { show } = useIntercom()

  const [isMobileMenuOpen, toggleMobileMenu] = useState(false)

  const handleMobileMenuToggle = useCallback(
    () => toggleMobileMenu(!isMobileMenuOpen),
    [isMobileMenuOpen]
  )

  const handleShowIntercom = useCallback(() => {
    toggleMobileMenu(false)
    show()
  }, [show])

  const handleHelpClick = () => {
    /*logAmplitudeEvent('help center clicked', {
      'help center': 'intercom',
      source: 'dashboard',
    })*/

    window.open(
      'https://helpcenter.soax.com/en',
      '_blank',
      'noopener,noreferrer'
    )
  }

  const navSections = [
    {
      items: [
        {
          label: 'Your products',
          icon: <HomeIcon />,
          to: '/',
          items: [
            {
              label: 'Scraping API',
              to: '/scraping',
              icon: <XmlIcon />,
            },
            {
              label: 'Proxy',
              to: '/proxy',
              icon: <ServerIcon />,
            },
            /*{
              label: 'Unblocker',
              to: '/unblocker',
              icon: <UnlockIcon />,
            },*/
          ],
        },
        {
          label: 'Statistics',
          to: '/statistics',
          icon: <ChartIcon />,
        },
      ],
    },
    {
      items: [
        {
          label: 'Settings',
          to: '/settings',
          icon: <SettingsIcon />,
        },
        {
          label: 'Support Chat',
          onClick: () => handleShowIntercom(),
          icon: <ChatIcon />,
        },
        {
          label: 'Help Center',
          onClick: () => handleHelpClick(),
          icon: <InfoIcon />,
        },
        {
          label: 'Sign out',
          href: '/logout',
          icon: <LogoutIcon />,
        },
      ],
    },
  ]

  return (
    <BrowserRouter>
      <MainLayout
        navSections={navSections}
        isMobileMenuOpen={isMobileMenuOpen}
        onToggleMobileMenu={handleMobileMenuToggle}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/scraping" element={<ScrapingPage />} />
          <Route path="/proxy" element={<ProxyPage />} />
          <Route path="/unblocker" element={<UnblockerPage />} />
          <Route path="/settings/pricing" element={<PricingPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/statistics" element={<StatisticsPage />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  )
}

export default RoutesComponent
