import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLoadingXl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.974 9.313c-.865-.231-1.765.282-1.878 1.17a12 12 0 1 0 7.01-9.44c-.816.365-1.047 1.375-.576 2.135.472.76 1.467.979 2.302.655a8.76 8.76 0 1 1-5.566 7.494c.069-.892-.428-1.782-1.292-2.014"
    />
  </svg>
)
export default SvgLoadingXl
