import React from 'react'

import MainHeader from 'components/navigation/MainHeader'
import SettingsContent from 'components/modules/settings/SettingsContent'

const SettingsPage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'Settings',
      isActive: true,
    },
  ]

  return (
    <>
      <MainHeader crumbsItems={crumbsItems} />
      <SettingsContent />
    </>
  )
}

export default SettingsPage
