import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInfoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 8.958c.345 0 .625.28.625.625v4.167a.625.625 0 1 1-1.25 0V9.583c0-.345.28-.625.625-.625M10 7.5a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.708 10a7.292 7.292 0 1 1 14.584 0 7.292 7.292 0 0 1-14.584 0M10 3.958a6.042 6.042 0 1 0 0 12.084 6.042 6.042 0 0 0 0-12.084"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgInfoCircle
