import React from 'react'

import MainHeader from 'components/navigation/MainHeader'
import ProxyContent from 'components/modules/proxy/ProxyContent'

const ProxyPage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'Your products',
      to: '/',
    },
    {
      label: 'Proxy',
      isActive: true,
    },
  ]

  return (
    <>
      <MainHeader crumbsItems={crumbsItems} />
      <ProxyContent />
    </>
  )
}

export default ProxyPage
