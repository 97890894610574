import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLogotype = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={152}
    height={32}
    fill="none"
    viewBox="0 0 152 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.071 8.852c0 2.83 2.553 3.6 8.284 4.27 10.489 1.08 14.737 3.806 14.746 9.354 0 6.019-5.014 9.516-15.351 9.516h-1.734C4.708 31.992 0 27.21 0 21.19h8.894c.291 3.558 3.477 5.175 8.249 5.335 4.918-.15 6.652-1.55 6.652-3.636 0-2.78-2.61-3.602-9.006-4.476C5.169 17.127.769 14.761.769 9.102.769 4.218 4.146 0 14.943 0q.204 0 .406.006.2.005.397.006C16.05 0 16.357 0 16.673 0c11.258 0 15.76 5.087 15.864 9.929h-9.203c-.294-2.388-2.75-4.26-7.807-4.454-3.982.164-5.456 1.456-5.456 3.377"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M55.349.004h.002c11.208 0 18.064 5.71 18.064 15.793v.36C73.415 26.29 66.559 32 55.35 32q-.172 0-.345-.009c-.174-.006-.348-.013-.52-.005-.286.014-.57.014-.863.014h-.002c-11.206 0-18.064-5.71-18.064-15.843v-.36C35.557 5.715 42.415.004 53.621.004q.172.001.346.008c.173.007.347.013.52.005.285-.013.572-.013.862-.013M44.802 15.895v.155c0 6.625 3.87 9.784 9.676 10.059 5.814-.279 9.677-3.437 9.677-10.059v-.155c0-6.57-3.863-9.727-9.677-10.008-5.814.28-9.676 3.438-9.676 10.008M95.547.004h-.002c-.292 0-.576 0-.857.013-.168.008-.342.002-.517-.005q-.177-.007-.354-.008c-12.28 0-15.3 5.966-15.914 10.7h9.054c.291-2.739 2.252-5.291 7.546-5.535 5.854.255 8.284 3.077 8.326 7.49v.663a41.8 41.8 0 0 0-10.295-1.333c-.283 0-.56 0-.834.012a7 7 0 0 1-.526-.004q-.186-.007-.37-.008c-10.797 0-14.022 4.733-14.022 9.877C76.782 26.394 79.188 32 89.627 32q.258-.001.512-.013l.174-.006q.509.018 1.044.019c4.835 0 8.862-1.826 11.487-4.942l-.015.159v3.96h9.204v-16.87c0-7.77-2.772-14.303-16.486-14.303m-9.62 21.606c0-2.26 1.45-4.609 7.455-4.82 3.124.108 6.84.794 9.185 1.784-.834 3.404-3.93 7.47-10.14 7.796-4.948-.25-6.5-2.527-6.5-4.76"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M150.567.827 139.218 15.23 152 31.179h-10.992l-7.613-10.442-7.696 10.442h-10.942l12.732-15.948L116.19.827h10.94l6.249 9.224 6.248-9.224z"
    />
  </svg>
)
export default SvgLogotype
