// import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware, compose } from 'redux'

import rootSaga from './root-saga'
import createRootReducer from './root-reducer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()

  /*const loggerMiddleware = createLogger({
    predicate: () => process.env.NODE_ENV === 'development',
  })*/

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  // @ts-ignore
  const store = createStore(
    createRootReducer(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)

  return store
}
