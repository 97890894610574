import React, { ReactElement } from 'react'

import styles from './MainLayout.module.scss'

import NavSidebar, {
  INavSection,
} from 'components/navigation/NavSidebarNew/NavSidebar'

export interface IMainLayout {
  navSections?: INavSection[]
  isMobileMenuOpen: boolean
  isFullFunctional: boolean
  topSlot?: ReactElement
  onToggleMobileMenu: () => void
  children: React.ReactNode
}

const MainLayout: React.FC<IMainLayout> = (props) => {
  const {
    navSections,
    isMobileMenuOpen,
    isFullFunctional = true,
    onToggleMobileMenu,
    children,
  } = props

  const currentYear = new Date().getFullYear()

  return (
    <main className={styles.wrapper}>
      <NavSidebar
        navSections={navSections}
        isMobileMenuOpen={isMobileMenuOpen}
        isFullFunctional={isFullFunctional}
        onToggleMobileMenu={onToggleMobileMenu}
      />

      <section className={styles.content}>
        <div className={styles.pageWrapper}>
          <div className={styles.pageContainer}>
            <div className={styles.pageContent}>{children}</div>

            <div className={styles.footerWrapper}>
              <div className={styles.footer}>
                <div className={styles.footerCred}>
                  {currentYear} © Soax Ltd. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default MainLayout
