import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEditAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.614 2.891a.625.625 0 0 0-.884 0l-7.66 7.66a.6.6 0 0 0-.163.285l-.833 3.19a.625.625 0 0 0 .762.763l3.19-.834a.6.6 0 0 0 .285-.162l7.66-7.66a.625.625 0 0 0 0-.885zm-7.54 8.425 7.098-7.099 1.473 1.473-7.098 7.099-1.994.52z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M3.333 16.042a.625.625 0 1 0 0 1.25h12.5a.625.625 0 0 0 0-1.25z"
    />
  </svg>
)
export default SvgEditAlt
