import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.427 13.49 4.104 8.167a.5.5 0 0 1 0-.707l.44-.44a.5.5 0 0 1 .706 0l4.396 4.396a.5.5 0 0 0 .708 0L14.75 7.02a.5.5 0 0 1 .707 0l.44.44a.5.5 0 0 1 0 .707l-5.324 5.323a.81.81 0 0 1-1.146 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCaretDown
