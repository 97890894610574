import React from 'react'

import styles from './HomeContent.module.scss'

import SubscriptionInfo from 'components/modules/settings/SubscriptionsInfo'
import PromoBlock from 'components/elements/blocks/PromoBlock'
import ProductCard from 'components/modules/pricing/ProductCard'
import {
  CheckedBox as CheckIcon,
  Present as PresentIcon,
  Timer as TimerIcon,
} from 'components/icons'

const trialPromoItems = [
  {
    label: '3 days',
    icon: <TimerIcon />,
  },
  {
    label: '$5 on your balance',
    icon: <PresentIcon />,
  },
  {
    label: 'All products',
    icon: <CheckIcon />,
  },
]

const products = [
  {
    title: 'Scraping API',
    description: (
      <div>
        <p>Don’t stuck with configuration.</p>
        <p>Just get the data you need.</p>
      </div>
    ),
    prices: [
      'eCommerce $2.2 per 1k req.',
      'Social $3.1 per 1k req.',
      'Business review $5.4 per 1k req.',
      'SERP $1.6 per 1k req.',
      'more APIs come soon',
    ],
    link: '/scraping',
  },
  {
    title: 'Proxy',
    description: 'Setup your proxy connection with tons of flexible options',
    prices: ['Residential', 'Mobile', 'DC', 'ISP', 'Single GEO'],
    link: '/proxy',
  },
]

const HomeContent: React.FC = () => {
  const currentPlan = {
    name: 'Pay as you go',
    status: 'active',
  }

  const productsList = products.map((product, index) => (
    <ProductCard
      product={product}
      isFullFunctional={!!currentPlan}
      className={styles.productCard}
      key={`home-page-product-card_${index}`}
    />
  ))

  return (
    <>
      <div className={styles.block}>
        <SubscriptionInfo />
      </div>

      {!currentPlan && (
        <PromoBlock
          title={'All soax products in 1 subscription'}
          image={`${process.env.PUBLIC_URL}/ball.png`}
          items={trialPromoItems}
          className={styles.block}
        />
      )}

      <div>
        <h3 className={styles.productsTitle}>
          {!!currentPlan
            ? 'Your available high-end products'
            : 'Subscribe to unlock all these high-end products'}
        </h3>

        <div className={styles.row}>{productsList}</div>
      </div>
    </>
  )
}

export default HomeContent
