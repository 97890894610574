import React, { useState } from 'react'

import styles from '../ProxyContent/ProxyContent.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import AddIPModal from './AddIPModal'
import WhitelistTable from './WhitelistTable'

const whitelist = [
  {
    ip: '89.56.125.1',
    comment: 'my',
  },
  {
    ip: '89.56.125.2',
    comment: 'iphone',
  },
  {
    ip: '89.56.125.3',
  },
  {
    ip: '89.56.125.4',
    comment: '',
  },
  {
    ip: '125.69.87.147',
  },
  {
    ip: '125.69.87.148',
  },
  {
    ip: '125.69.87.149',
    comment: 'work',
  },
  {
    ip: '192.168.0.1',
    comment: 'Peter',
  },
  {
    ip: '192.168.0.2',
  },
  {
    ip: '192.168.0.3',
  },
  {
    ip: '192.168.0.4',
    comment: 'Kolya',
  },
]

const ProxyWhitelist: React.FC = () => {
  const [isModalShown, toggleModal] = useState(false)

  const handleAddIPClick = () => {
    toggleModal(true)
  }

  const handleModalClose = () => {
    toggleModal(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentBlock}>
        <div className={styles.blockHeader}>
          <span className={styles.blockTitle}>IP whitelist management</span>
        </div>

        {(!whitelist || !whitelist.length) && (
          <div className={styles.whitelistBlock}>You have 100 slots to add</div>
        )}

        <div className={styles.whitelistBlock}>
          <CustomButton color={'outline'} onClick={handleAddIPClick}>
            Add IP to whitelist
          </CustomButton>
        </div>

        {(!whitelist || !whitelist.length) && (
          <div className={styles.whitelistBlock}>No IPs added</div>
        )}

        {!!whitelist && !!whitelist.length && (
          <WhitelistTable items={whitelist} />
        )}
      </div>

      <AddIPModal isModalShown={isModalShown} onModalClose={handleModalClose} />
    </div>
  )
}

export default ProxyWhitelist
