import React from 'react'

import styles from './StatisticsPage.module.scss'

const orbStatisticsLink =
  'https://portal.withorb.com/view?token=ImdoVlBYWUZvWjVoU21kNm0i.6IsTeiwH-XmsNc0K7u4uttK_StY'

const StatisticsPage: React.FC = () => {
  return (
    <section className={styles.container}>
      <iframe
        src={orbStatisticsLink}
        frameBorder="0"
        title={'Orb statistics'}
        allow="camera;microphone"
      />
    </section>
  )
}

export default StatisticsPage
