import React from 'react'

import MainHeader from 'components/navigation/MainHeader'
import ScrapingContent from 'components/modules/scraping/ScrapingContent'

const ScrapingPage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'Your products',
      to: '/',
    },
    {
      label: 'Scraping API',
      isActive: true,
    },
  ]

  return (
    <>
      <MainHeader crumbsItems={crumbsItems} />
      <ScrapingContent />
    </>
  )
}

export default ScrapingPage
