import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrashAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.333 1.875a.625.625 0 0 0-.625.625v.625H4.167a.625.625 0 1 0 0 1.25h11.666a.625.625 0 1 0 0-1.25h-3.541V2.5a.625.625 0 0 0-.625-.625zM10.884 12.5l1.225 1.225a.625.625 0 1 1-.884.884L10 13.384l-1.225 1.225a.625.625 0 1 1-.884-.884L9.116 12.5l-1.225-1.225a.625.625 0 0 1 .884-.884L10 11.616l1.225-1.225a.625.625 0 1 1 .884.884z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.993 6.598a.625.625 0 0 1 .621-.556h8.772c.319 0 .586.24.621.556l.167 1.5a37 37 0 0 1 0 8.19l-.016.147a2.38 2.38 0 0 1-2.034 2.092c-2.072.29-4.175.29-6.248 0a2.38 2.38 0 0 1-2.033-2.092l-.017-.148a37 37 0 0 1 0-8.188zm1.18.694-.104.945a35.8 35.8 0 0 0 0 7.912l.016.148c.057.512.454.92.964.992a21.3 21.3 0 0 0 5.902 0c.51-.071.907-.48.964-.992l.017-.148c.292-2.63.292-5.283 0-7.912l-.105-.945z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgTrashAlt
