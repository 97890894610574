import React, { ReactNode } from 'react'

import styles from './TabPanels.module.scss'

interface ITabPanelsProps {
  items: ReactNode[]
  activeTabIndex: number
  className?: string
}

const TabPanels: React.FC<ITabPanelsProps> = (props) => {
  const { items, activeTabIndex, className } = props

  const itemsList = items.map((item, index) => (
    <div
      className={`
        ${styles.item}
        ${activeTabIndex === index ? styles.active : ''}
      `}
      key={`tab-panels-item_${index}`}
    >
      {item}
    </div>
  ))

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      {itemsList}
    </div>
  )
}

export default TabPanels
