import React from 'react'

import styles from './ProductPricingBlock.module.scss'

import { Card as CardIcon } from '../../../icons'

interface IProductPricingProps {
  product: string
  price: string
  className?: string
}

const ProductPricingBlock: React.FC<IProductPricingProps> = (props) => {
  const { price, product, className } = props

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div className={styles.header}>
        <div className={styles.iconContainer}>
          <CardIcon />
        </div>

        <div className={styles.title}>Selected product pricing</div>
      </div>

      <div className={styles.product}>{product}</div>

      <div className={styles.separator} />

      <div className={styles.price}>${price}</div>
    </div>
  )
}

export default ProductPricingBlock
