import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './MainHeader.module.scss'

import Breadcrumbs, {
  IBreadcrumbsProps,
} from 'components/navigation/Breadcrumbs/Breadcrumbs'
import {
  Wallet as WalletIcon,
  /*User as ProfileIcon,*/
  Notification as NotificationIcon,
} from '../../icons'

interface IMainHeaderProps {
  crumbsItems: IBreadcrumbsProps['items']
}

const MainHeader: React.FC<IMainHeaderProps> = (props) => {
  const { crumbsItems } = props

  const balance = 54.9

  const handleNotifications = () => console.log('Notific')

  return (
    <header className={styles.header}>
      <Breadcrumbs items={crumbsItems} />

      <div className={styles.itemsContainer}>
        <NavLink to={'/balance'} className={styles.item}>
          <span className={styles.iconWrapper}>
            <WalletIcon />
          </span>

          <span className={styles.itemLabel}>{`Balance $${balance}`}</span>
        </NavLink>

        {/*<NavLink to={'/profile'} className={styles.item}>
          <span className={styles.iconWrapper}>
            <ProfileIcon />
          </span>

          <span className={styles.itemLabel}>Profile</span>
        </NavLink>*/}

        <button className={styles.item} onClick={() => handleNotifications()}>
          <span className={styles.iconWrapper}>
            <NotificationIcon />
          </span>

          <span className={styles.itemLabel}>Notifications</span>
        </button>
      </div>
    </header>
  )
}

export default MainHeader
