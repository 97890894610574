import React from 'react'

import styles from './PricingPage.module.scss'

import PricingContent from 'components/modules/pricing/PricingContent'
import {
  FolderOpen as FolderIcon,
  Server as ServerIcon,
  Stack as DataIcon,
  Unlock as UnlockIcon,
} from '../../components/icons'

const products = [
  {
    label: 'Scraping API',
    icon: <FolderIcon />,
  },
  {
    label: 'Proxy',
    icon: <ServerIcon />,
  },
  {
    label: 'Web Unblocker',
    icon: <UnlockIcon />,
  },
  {
    label: 'Datasets',
    icon: <DataIcon />,
  },
]

const PricingPage: React.FC = () => {
  const productsList = products.map((product, index) => (
    <div className={styles.productItem} key={`pricing-products-item_${index}`}>
      <div className={styles.productItemIconWrapper}>{product.icon}</div>
      {product.label}
    </div>
  ))

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>All soax products in 1 subscription</h2>

      <div className={styles.productsList}>{productsList}</div>

      <h3 className={styles.subtitle}>Select subscription plan</h3>

      <PricingContent />
    </div>
  )
}

export default PricingPage
