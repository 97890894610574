import React, { ReactElement, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CustomButton from '../CustomButton'
import { ICustomButtonProps } from '../CustomButton/CustomButton'

import styles from './CopyButton.module.scss'

interface ICopyButton extends ICustomButtonProps {
  text: string
  children?: string | ReactElement
}

const CopyButton: React.FC<ICopyButton> = (props) => {
  const { text, size, color, children, className } = props

  const [copiedVar, setCopiedVar] = useState<boolean | null>(null)

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setCopiedVar(true)
          setTimeout(() => setCopiedVar(false), 300)
        }}
      >
        <CustomButton size={size} color={color}>
          {children || 'Copy'}
        </CustomButton>
      </CopyToClipboard>

      <span
        className={`
          ${styles.tip}
          ${copiedVar ? styles.active : ''}
        `}
      >
        Copied
      </span>
    </div>
  )
}

export default CopyButton
