import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'

import styles from './ProxyConfigurationForm.module.scss'

import { randomRegion, randomCity, randomIsp } from 'utils/constants'
import { InfoCircle as InfoIcon } from '../../../icons'
import Flag from 'components/elements/other/Flag'
import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomCheckbox from 'components/elements/inputs/CustomCheckbox'
import CustomInput from 'components/elements/inputs/CustomInput'
import CustomRadio from 'components/elements/inputs/CustomRadio'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'

interface IProxyConfigurationFormProps {
  selectedTimeInterval: any
  selectedCountry: any
  selectedRegion: any
  selectedCity: any
  selectedIsp: any
  isBindIPActive: boolean
  activeSessionLengthType: any
  sessionPrefix: string
  connectionsAmount?: string
  isConnectionsShown?: boolean
  availableConnectionsAmount?: string
  onTimeIntervalChange: (option: ISelectOption) => void
  onCountryChange: (option: ISelectOption) => void
  onRegionChange: (option: ISelectOption) => void
  onCityChange: (option: ISelectOption) => void
  onIspChange: (option: ISelectOption) => void
  onBindIp: () => void
  onActiveSessionLengthChange: (e: any) => void
  onSessionPrefixChange: (e: any) => void
  onConnectionsAmountChange?: (e: any) => void
}

const countriesOptions = [
  {
    label: 'Any country',
    value: 'any',
  },
  {
    label: 'Turkey',
    value: 'TR',
  },
  {
    label: 'Albania',
    value: 'AL',
  },
  {
    label: 'India',
    value: 'IN',
  },
]

const regionOptions = [
  {
    label: 'Any region',
    value: 'any',
  },
  {
    label: 'antalya',
    value: 'antalya',
  },
  {
    label: 'afyonkarahisar province',
    value: 'afyonkarahisar province',
  },
]

const citiesOptions = [
  {
    label: 'Any city',
    value: 'any',
  },
  {
    label: 'trabzon',
    value: 'trabzon',
  },
  {
    label: 'magnesia ad sipylum',
    value: 'magnesia ad sipylum',
  },
]

const ispOptions = [
  {
    label: 'Any ISP',
    value: 'any',
  },
  {
    label: 'airnet',
    value: 'airnet',
  },
  {
    label: 'alanyanet',
    value: 'alanyanet',
  },
]

const ProxyConfigurationForm: React.FC<IProxyConfigurationFormProps> = (
  props
) => {
  const {
    selectedCountry,
    selectedRegion,
    selectedCity,
    selectedIsp,
    activeSessionLengthType,
    selectedTimeInterval,
    isBindIPActive,
    isConnectionsShown,
    sessionPrefix,
    connectionsAmount,
    availableConnectionsAmount,
    onConnectionsAmountChange,
    onCountryChange,
    onRegionChange,
    onCityChange,
    onIspChange,
    onActiveSessionLengthChange,
    onTimeIntervalChange,
    onBindIp,
    onSessionPrefixChange,
  } = props

  const timeIntervals = [
    {
      label: '20 min',
      value: '1200',
    },
    {
      label: '30 min',
      value: '1800',
    },
    {
      label: '40 min',
      value: '2400',
    },
  ]

  const iconedCountriesOptions = countriesOptions.map((item) => ({
    ...item,
    icon:
      item.value === 'any' ? null : (
        <Flag countryCode={item.value} className={styles.optionFlag} />
      ),
  }))

  const [isDeepTargetsActive, toggleDeepTargets] = useState(false)

  const handleDeepTargets = () => {
    if (isDeepTargetsActive) {
      onRegionChange(randomRegion)
      onCityChange(randomCity)
      onIspChange(randomIsp)
    }

    toggleDeepTargets(!isDeepTargetsActive)
  }

  const handleCountryChange = (option: any) => {
    onCountryChange(option)

    handleRegionChange(randomRegion)
    handleCityChange(randomCity)
    handleIspChange(randomIsp)
  }

  const handleRegionChange = (option: any) => {
    onRegionChange(option)

    handleCityChange(randomCity)
  }

  const handleCityChange = (option: any) => {
    onCityChange(option)
  }

  const handleIspChange = (option: any) => {
    onIspChange(option)
  }

  const handleTimeIntervalChange = (option: any) => {
    onTimeIntervalChange(option)
  }

  return (
    <div className={styles.form}>
      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>1</div>
            <div className={styles.formLine} />
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup
            labelTop={'Target location'}
            className={styles.targetGroup}
          >
            <CustomSelect
              onChange={handleCountryChange}
              value={selectedCountry}
              name={'quick-connect-target-country'}
              options={iconedCountriesOptions}
            />
          </InputGroup>

          <CustomCheckbox
            id={'deep-targets-toggle'}
            checked={isDeepTargetsActive}
            onChange={handleDeepTargets}
          >
            Deep targets levels
          </CustomCheckbox>

          <div
            className={`
              ${styles.deepTargetBlock}
              ${isDeepTargetsActive ? styles.active : ''}
            `}
          >
            <CustomSelect
              onChange={handleRegionChange}
              value={selectedRegion}
              name={'quick-connect-target-region'}
              options={regionOptions}
              className={styles.deepTargetSelect}
              disabled={selectedCountry.value === 'any'}
            />

            <CustomSelect
              onChange={handleCityChange}
              value={selectedCity}
              name={'quick-connect-target-city'}
              options={citiesOptions}
              className={styles.deepTargetSelect}
              disabled={selectedCountry.value === 'any'}
            />

            <CustomSelect
              onChange={handleIspChange}
              value={selectedIsp}
              name={'quick-connect-target-isp'}
              options={ispOptions}
              className={styles.deepTargetSelect}
              disabled={selectedCountry.value === 'any'}
            />
          </div>
        </div>
      </div>

      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>2</div>
            <div className={styles.formLine} />
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup labelTop={'Session length (IP refresh rate)'}>
            <div className={styles.groupInnerBlock}>
              <div className={styles.radioWrapper}>
                <CustomRadio
                  id={'same-ip-radio'}
                  name={'session-length'}
                  value={'same'}
                  checked={activeSessionLengthType === 'same'}
                  onChange={onActiveSessionLengthChange}
                >
                  Same IP up to
                </CustomRadio>

                <CustomSelect
                  className={styles.timeIntervalSelect}
                  type={'ghost'}
                  value={selectedTimeInterval}
                  name={'time-interval-select'}
                  options={timeIntervals}
                  onChange={handleTimeIntervalChange}
                />

                <div className={styles.bindIpBlock}>
                  <CustomCheckbox
                    id={'deep-targets-toggle'}
                    checked={isBindIPActive}
                    onChange={onBindIp}
                  >
                    Bind IP
                  </CustomCheckbox>

                  <div
                    className={styles.infoIconWrapper}
                    data-tip
                    data-tooltip-id={`bind_ip_notice`}
                  >
                    <InfoIcon />
                  </div>
                </div>
              </div>

              <div className={styles.radioWrapper}>
                <CustomRadio
                  id={'new-ip-radio'}
                  name={'session-length'}
                  value={'new'}
                  checked={activeSessionLengthType === 'new'}
                  onChange={onActiveSessionLengthChange}
                >
                  New IP on each request
                </CustomRadio>
              </div>
            </div>
          </InputGroup>
        </div>

        <Tooltip id={`bind_ip_notice`} place={'bottom'}>
          Bind IP description
        </Tooltip>
      </div>

      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>3</div>
            <div className={styles.formLine} />
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup
            labelTop={'Session name prefix'}
            description={'Session name description'}
          >
            <CustomInput
              value={sessionPrefix}
              onChange={onSessionPrefixChange}
            />
          </InputGroup>
        </div>
      </div>

      {isConnectionsShown && (
        <div className={styles.formBlock}>
          <div>
            <div className={styles.formLeft}>
              <div className={styles.formNumber}>4</div>
              <div className={styles.formLine} />
            </div>
          </div>

          <div className={styles.formRight}>
            <InputGroup
              labelTop={'Connections amount'}
              childrenDirection={'vertical'}
            >
              <CustomInput
                value={connectionsAmount}
                onChange={onConnectionsAmountChange}
              />
              <span className={styles.inputGroupBottomNotice}>
                Available {availableConnectionsAmount} more connections
              </span>
            </InputGroup>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProxyConfigurationForm
