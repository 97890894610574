import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.987 4.094 10 3.958l-5.986.136a2.34 2.34 0 0 0-2.25 1.917 22.1 22.1 0 0 0 0 7.979 2.34 2.34 0 0 0 2.25 1.916l5.986.136 5.986-.136a2.34 2.34 0 0 0 2.25-1.916 22.1 22.1 0 0 0 0-7.98 2.34 2.34 0 0 0-2.25-1.916M4.041 5.344 10 5.208l5.958.134a1.09 1.09 0 0 1 1.048.894q.192 1.043.276 2.096H2.718q.084-1.053.276-2.096a1.09 1.09 0 0 1 1.048-.894M2.65 10c0 1.26.114 2.52.343 3.764a1.09 1.09 0 0 0 1.048.893l5.958.135 5.958-.135a1.09 1.09 0 0 0 1.048-.893c.229-1.244.343-2.504.343-3.764z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCard
