import React, { useState } from 'react'

import styles from './PlansComparisonTable.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import SubColumn from './SubColumn'

import { capitalizeWord } from 'utils/functions'
import { popularPlanId, pricingStructure } from 'utils/constants'

interface IPlansComparisonTable {
  plans: any[]
  isTrialAvailable: boolean
  className?: string
}

/*const prices = {
  441: {
    social_advanced_facebook: '2.50',
    social_essential_instagram: '2.20',
    social_advanced_instagram: '2.50',
    social_extended_instagram: '2.10',
    social_advanced_linkedin: '2.30',
  }
}*/

const PlansComparisonTable: React.FC<IPlansComparisonTable> = (props) => {
  const { plans, isTrialAvailable, className } = props

  const [isCommerceOpened, toggleCommerce] = useState(false)
  const [isUnblockerOpened, toggleUnblocker] = useState(false)
  const [isSerpOpened, toggleSerp] = useState(false)
  const [isSocialOpened, toggleSocial] = useState(false)
  const [isBusinessOpened, toggleBusiness] = useState(false)
  const [isTicketsOpened, toggleTickets] = useState(false)

  const isTrialAvailableForPlan = (plan: any) => {
    return isTrialAvailable && plan.trial_price !== undefined
  }

  const getBtnLabel = (plan: any) => {
    if (isTrialAvailableForPlan(plan)) {
      if (!!plan.trial_price) {
        return `Try for $${plan.trial_price}`
      } else {
        return 'Get Free Trial'
      }
    } else {
      return 'Subscribe'
    }
  }

  const handlePlanClick = (plan: any) => {
    console.log('Click', plan)
  }

  const handleCommerceToggle = () => {
    toggleCommerce(!isCommerceOpened)
  }

  const handleUnblockerToggle = () => {
    toggleUnblocker(!isUnblockerOpened)
  }

  const handleSerpToggle = () => {
    toggleSerp(!isSerpOpened)
  }

  const handleSocialToggle = () => {
    toggleSocial(!isSocialOpened)
  }

  const handleBusinessToggle = () => {
    toggleBusiness(!isBusinessOpened)
  }

  const handleTicketsToggle = () => {
    toggleTickets(!isTicketsOpened)
  }

  if (!plans.length) return null

  const plansUpperList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlanId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-upper_${plan.id}`}
    >
      <div className={styles.colHeader}>
        <div className={styles.planHeader}>
          <div className={styles.planName}>
            <span>{capitalizeWord(plan.name)}</span>
          </div>
          <div className={styles.planPrice}>
            ${plan.amount}
            <span>/mo</span>
          </div>
        </div>
      </div>
    </div>
  ))

  const scrapingPriceList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlanId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-col_${plan.id}`}
    >
      <SubColumn
        items={pricingStructure.scraping.eCommerce}
        state={isCommerceOpened}
        plan={plan}
        handler={handleCommerceToggle}
      />

      <SubColumn
        items={pricingStructure.scraping.Unblocker}
        state={isUnblockerOpened}
        plan={plan}
        handler={handleUnblockerToggle}
      />

      <SubColumn
        items={pricingStructure.scraping.SERP}
        state={isSerpOpened}
        plan={plan}
        handler={handleSerpToggle}
      />

      <SubColumn
        items={pricingStructure.scraping['Social Networks']}
        state={isSocialOpened}
        plan={plan}
        handler={handleSocialToggle}
      />

      <SubColumn
        items={pricingStructure.scraping['Business Reviews']}
        state={isBusinessOpened}
        plan={plan}
        handler={handleBusinessToggle}
      />

      <SubColumn
        items={pricingStructure.scraping.Tickets}
        state={isTicketsOpened}
        plan={plan}
        handler={handleTicketsToggle}
      />
    </div>
  ))

  const proxyPriceList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlanId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-col_${plan.id}`}
    >
      <div className={styles.cell}>$6.5</div>
      <div className={styles.cell}>$6.5</div>
      <div className={styles.cell}>$6.5</div>
      <div className={styles.cell}>$6.5</div>
    </div>
  ))

  const unblockerPriceList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlanId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-col_${plan.id}`}
    >
      <div className={styles.cell}>$8.5</div>
    </div>
  ))

  const plansLowerList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlanId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-lower_${plan.id}`}
    >
      <div className={styles.colFooter}>
        <div className={styles.planHeader}>
          <div className={styles.planName}>
            <span>{capitalizeWord(plan.name)}</span>
          </div>
          <div className={styles.planPrice}>
            ${plan.amount}
            <span>/mo</span>
          </div>
        </div>

        <div className={styles.btnWrapper}>
          <CustomButton
            size={'l'}
            color={popularPlanId.includes(plan.id) ? 'blue' : 'blueOutline'}
            largeRadius={true}
            className={styles.planBtn}
            // loading={plan.id === processingPlanId}
            // disabled={subscriptionProcessing}
            onClick={() => handlePlanClick(plan)}
          >
            {getBtnLabel(plan)}
          </CustomButton>
        </div>
      </div>
    </div>
  ))

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <h3 className={styles.title}>Compare plans</h3>

      <div
        className={`
          ${styles.table}
          ${styles.upperTable}
        `}
      >
        <div className={styles.tableCol}>
          <div className={styles.colHeader} />
        </div>

        {plansUpperList}
      </div>

      <div className={styles.subheader}>
        <div className={styles.tableCol}>
          <div className={styles.subheaderTitle}>Scraping API</div>
        </div>
        <div className={styles.tableCol}>
          <div className={styles.subheaderDescription}>Price per 1000 req.</div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.tableCol}>
          <SubColumn
            title={'eCommerce'}
            items={pricingStructure.scraping.eCommerce}
            state={isCommerceOpened}
            handler={handleCommerceToggle}
          />

          <SubColumn
            title={'Unblocker'}
            items={pricingStructure.scraping.Unblocker}
            state={isUnblockerOpened}
            handler={handleUnblockerToggle}
          />

          <SubColumn
            title={'SERP'}
            items={pricingStructure.scraping.SERP}
            state={isSerpOpened}
            handler={handleSerpToggle}
          />

          <SubColumn
            title={'Social Networks'}
            items={pricingStructure.scraping['Social Networks']}
            state={isSocialOpened}
            handler={handleSocialToggle}
          />

          <SubColumn
            title={'Business Reviews'}
            items={pricingStructure.scraping['Business Reviews']}
            state={isBusinessOpened}
            handler={handleBusinessToggle}
          />

          <SubColumn
            title={'Tickets'}
            items={pricingStructure.scraping.Tickets}
            state={isTicketsOpened}
            handler={handleTicketsToggle}
          />
        </div>

        {scrapingPriceList}
      </div>

      <div className={styles.subheader}>
        <div className={styles.tableCol}>
          <div className={styles.subheaderTitle}>Proxy</div>
        </div>
        <div className={styles.tableCol}>
          <div className={styles.subheaderDescription}>Price per 1 GB</div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.tableCol}>
          <div className={styles.cell}>Residential Proxy</div>

          <div className={styles.cell}>Mobile Proxy</div>

          <div className={styles.cell}>ISP Proxy</div>

          <div className={styles.cell}>DC Proxy</div>
        </div>

        {proxyPriceList}
      </div>

      <div className={styles.subheader}>
        <div className={styles.tableCol}>
          <div className={styles.subheaderTitle}>Web Unblocker</div>
        </div>
        <div className={styles.tableCol}>
          <div className={styles.subheaderDescription}>Price per 1000 req.</div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.tableCol}>
          <div className={styles.cell}>Unblocker Standard</div>
        </div>

        {unblockerPriceList}
      </div>

      <div
        className={`
          ${styles.table}
          ${styles.lowerTable}
        `}
      >
        <div className={styles.tableCol} />

        {plansLowerList}
      </div>
    </div>
  )
}

export default PlansComparisonTable
