import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSandWatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.47 8.53a.75.75 0 0 0 1.06 0l1-1A.75.75 0 0 0 13 6.25h-2a.75.75 0 0 0-.53 1.28zM11.47 15.47a.75.75 0 0 1 1.06 0l1 1a.75.75 0 0 1-.53 1.28h-2a.75.75 0 0 1-.53-1.28z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.149 12 .187-.161a21.1 21.1 0 0 0 4.844-6.057 1.428 1.428 0 0 0-1.135-2.094l-1.174-.102a44.5 44.5 0 0 0-7.742 0l-1.174.102A1.428 1.428 0 0 0 5.82 5.782a21.1 21.1 0 0 0 4.844 6.057l.187.161-.187.161A21.1 21.1 0 0 0 5.82 18.22a1.428 1.428 0 0 0 1.135 2.093l1.174.102c2.576.226 5.166.226 7.742 0l1.174-.102a1.428 1.428 0 0 0 1.135-2.093 21.1 21.1 0 0 0-4.844-6.058zm-1.145-.994.002-.002.351-.302a19.6 19.6 0 0 0 4.447-5.529L15.74 5.08a43 43 0 0 0-7.48 0l-1.065.093a19.6 19.6 0 0 0 4.448 5.53l.35.302.003.001h.008m.002 1.99-.002-.002h-.008l-.003.002-.35.302a19.6 19.6 0 0 0-4.448 5.53l1.064.092c2.49.218 4.993.218 7.481 0l1.064-.093a19.6 19.6 0 0 0-4.447-5.53z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSandWatch
