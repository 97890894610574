import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M9.334 22.667 22.667 9.333M9.334 9.333l13.333 13.334"
    />
  </svg>
)
export default SvgCross
