import React from 'react'

import styles from './AddIPModal.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'

import CustomModal from 'components/elements/modal/CustomModal'

interface IAddIPModal {
  isModalShown: boolean
  onModalClose: () => void
}

const AddIPModal: React.FC<IAddIPModal> = (props) => {
  const { isModalShown, onModalClose } = props

  return (
    <CustomModal
      title={'Add IP to whitelist'}
      isModalOpen={isModalShown}
      shouldCloseOnOverlayClick
      showCross
      modalWidth={'360px'}
      onModalClose={onModalClose}
    >
      <InputGroup
        labelTop={'IP address'}
        childrenDirection={'vertical'}
        bottomNotice={'Example: 192.168.1.1'}
        className={styles.group}
      >
        <CustomInput />
      </InputGroup>

      <InputGroup labelTop={'Label'} className={styles.group}>
        <CustomInput />
      </InputGroup>

      <CustomButton wide>Save</CustomButton>
    </CustomModal>
  )
}

export default AddIPModal
