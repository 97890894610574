export const popularPlanId = [442]

export const pricingStructure = {
  scraping: {
    title: 'Scraping API',
    eCommerce: [
      {
        label: 'eCommerce High Load API',
        productId: 'ecommerce_high_load',
      },
      {
        label: 'eCommerce Top Tier API',
        productId: 'ecommerce_top_tier',
      },
      {
        label: 'Walmart Direct API',
        productId: 'ecommerce_walmart_unblocker',
      },
      {
        label: 'Shopee Direct API',
        productId: 'ecommerce_shopee_unblocker',
      },
    ],
    Unblocker: [
      {
        label: 'Unblocker Top Tier API',
        productId: 'unblocker_top_tier',
      },
    ],
    SERP: [
      {
        label: 'Google Search API',
        productId: 'serp_essential_google_search',
      },
      {
        label: 'Yandex Search Advanced API',
        productId: 'serp_advanced_yandex_search',
      },
      {
        label: 'Yahoo! Search Advanced API',
        productId: 'serp_advanced_yahoo_search',
      },
      {
        label: 'Naver Search Advanced API',
        productId: 'serp_advanced_naver_search',
      },
      {
        label: 'Google Search Advanced API',
        productId: 'serp_advanced_google_search',
      },
      {
        label: 'Google Images Advanced API',
        productId: 'serp_advanced_google_images',
      },
      {
        label: 'DuckDuckGo Search Advanced API',
        productId: 'serp_advanced_duckduckgo_search',
      },
      {
        label: 'Bing Search Advanced API',
        productId: 'serp_advanced_bing_search',
      },
      {
        label: 'Baidu Search Advanced API',
        productId: 'serp_advanced_baidu_search',
      },
    ],
    'Social Networks': [
      {
        label: 'Instagram API',
        productId: 'social_extended_instagram',
      },
      {
        label: 'LinkedIn API',
        productId: 'social_extended_linkedin',
      },
      {
        label: 'YouTube API',
        productId: 'social_extended_youtube',
      },
      {
        label: 'TikTok API',
        productId: 'social_extended_tiktok',
      },
      {
        label: 'Twitter API',
        productId: 'social_extended_twitter',
      },
    ],
    'Business Reviews': [
      {
        label: 'G2 Unblocker API',
        productId: 'business_reviews_g2_unblocker',
      },
    ],
    Tickets: [
      {
        label: 'Ticketmaster Unblocker API',
        productId: 'tickets_ticketmaster_unblocker',
      },
    ],
  },
}

export const randomCountry = {
  label: 'Any country',
  value: 'any',
}

export const randomRegion = {
  label: 'Any region',
  value: 'any',
}

export const randomCity = {
  label: 'Any city',
  value: 'any',
}

export const randomIsp = {
  label: 'Any ISP',
  value: 'any',
}

export const curlResultSample =
  'curl ‘https://checker.soax.com/api/ipinfo’ -U ‘package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}:UJWOUJZBIPb0swh2’ -x ‘proxy.soax.com:5000’'

export const credResultSample =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}'

export const urlResultSample =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}:UJWOUJZBIPb0swh2@proxy.soax.com:5000'

export const proxyListResultSample =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}:UJWOUJZBIPb0swh2@proxy.soax.com:5000'

/*export const pricingStructure = {
  scraping: {
    title: 'Scraping API',
    items: [
      {
        title: 'eCommerce',
        items: [
          {
            label: 'eCommerce High Load API',
            productId: 'ecommerce_high_load',
          },
          {
            label: 'eCommerce Top Tier API',
            productId: 'ecommerce_top_tier',
          },
          {
            label: 'Walmart Direct API',
            productId: 'ecommerce_walmart_unblocker',
          },
          {
            label: 'Shopee Direct API',
            productId: 'ecommerce_shopee_unblocker',
          },
        ],
      },
      {
        title: 'Unblocker',
        items: [
          {
            label: 'Unblocker Top Tier API',
            productId: 'unblocker_top_tier',
          },
        ],
      },
      {
        title: 'SERP',
        items: [
          {
            label: 'Google Search API',
            productId: 'serp_essential_google_search',
          },
          {
            label: 'Yandex Search Advanced API',
            productId: 'serp_advanced_yandex_search',
          },
          {
            label: 'Yahoo! Search Advanced API',
            productId: 'serp_advanced_yahoo_search',
          },
          {
            label: 'Naver Search Advanced API',
            productId: 'serp_advanced_naver_search',
          },
          {
            label: 'Google Search Advanced API',
            productId: 'serp_advanced_google_search',
          },
          {
            label: 'Google Images Advanced API',
            productId: 'serp_advanced_google_images',
          },
          {
            label: 'DuckDuckGo Search Advanced API',
            productId: 'serp_advanced_duckduckgo_search',
          },
          {
            label: 'Bing Search Advanced API',
            productId: 'serp_advanced_bing_search',
          },
          {
            label: 'Baidu Search Advanced API',
            productId: 'serp_advanced_baidu_search',
          },
        ],
      },
      {
        title: 'Social Networks',
        items: [
          {
            label: 'Instagram API',
            productId: 'social_extended_instagram',
          },
          {
            label: 'LinkedIn API',
            productId: 'social_extended_linkedin',
          },
          {
            label: 'YouTube API',
            productId: 'social_extended_youtube',
          },
          {
            label: 'TikTok API',
            productId: 'social_extended_tiktok',
          },
          {
            label: 'Twitter API',
            productId: 'social_extended_twitter',
          },
        ],
      },
      {
        title: 'Business Reviews',
        items: [
          {
            label: 'G2 Unblocker API',
            productId: 'business_reviews_g2_unblocker',
          },
        ],
      },
      {
        title: 'Tickets',
        items: [
          {
            label: 'Ticketmaster Unblocker API',
            productId: 'tickets_ticketmaster_unblocker',
          },
        ],
      },
    ],
  },
}*/
