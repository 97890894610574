import React from 'react'

import InputGroup from 'components/elements/forms/InputGroup'
import CopyBlock from 'components/elements/blocks/CopyBlock'

interface ICurlResultProps {
  result: string
}

const CurlResult: React.FC<ICurlResultProps> = (props) => {
  const { result } = props

  return (
    <InputGroup labelTop={'Copy-paste this URL to your soft'}>
      <CopyBlock text={result} />
    </InputGroup>
  )
}

export default CurlResult
