import React from 'react'

import styles from './ValidationMessage.module.scss'

interface IValidationMessage {
  className?: string
  children?: React.ReactNode
}

const ValidationMessage: React.FC<IValidationMessage> = (props) => {
  const { className, children } = props

  return (
    <span
      className={`
        ${styles.message}
        ${className || ''}
      `}
    >
      {children}
    </span>
  )
}

export default ValidationMessage
