import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.298 4.612a1.875 1.875 0 0 0-2.596 0l-3.856 3.7a.62.62 0 0 0-.181.337 22.8 22.8 0 0 0-.101 7.626l.094.6h2.48V11.7c0-.345.28-.625.625-.625h4.474c.345 0 .625.28.625.625v5.176h2.48l.094-.6c.395-2.53.361-5.108-.1-7.626a.62.62 0 0 0-.182-.338zM7.836 3.71a3.125 3.125 0 0 1 4.328 0l3.855 3.7c.284.272.475.627.546 1.013.487 2.657.523 5.376.106 8.045l-.15.964a.82.82 0 0 1-.81.693h-3.474a.625.625 0 0 1-.625-.625v-5.176H8.388V17.5c0 .345-.28.625-.625.625H4.29a.82.82 0 0 1-.81-.693l-.15-.964a24 24 0 0 1 .106-8.045c.071-.386.262-.741.546-1.014z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgHome
