import React, { useCallback } from 'react'

import { ISubscriptionCardProps } from 'models/pricing'

import styles from './SubscriptionCard.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'

const SubscriptionCard: React.FC<ISubscriptionCardProps> = (props) => {
  const {
    plan,
    className,
    isTrialAvailable,
    subscriptionProcessing,
    planProcessing,
    promoLabel,
    isCustomPlan,
    onPlanClick,
  } = props

  /*const isTrialAvailableForPlan =
    isTrialAvailable &&
    (plan.trial_price !== undefined || plan.proxy_type === 'enterprise')*/

  const isTrialAvailableForPlan =
    isTrialAvailable &&
    (plan.trial_price !== undefined || plan.proxy_type === 'enterprise')

  const handleClick = useCallback(() => {
    onPlanClick(plan, isTrialAvailableForPlan)
  }, [onPlanClick, plan, isTrialAvailableForPlan])

  const renderButton = useCallback(() => {
    if (isCustomPlan) {
      return (
        <CustomButton
          className={styles.btn}
          onClick={handleClick}
          loading={planProcessing}
          disabled={subscriptionProcessing}
          largeRadius={true}
          size={'l'}
          color={'outline'}
        >
          Contact Sales
        </CustomButton>
      )
    } else if (isTrialAvailableForPlan) {
      return (
        <CustomButton
          className={`
            ${styles.btn}
            ${!!promoLabel ? styles.highlighted : ''}
          `}
          onClick={handleClick}
          loading={planProcessing}
          disabled={subscriptionProcessing}
          largeRadius={true}
          size={'l'}
        >
          {!!plan.trial_price
            ? `Try for $${plan.trial_price}`
            : 'Get Free Trial'}
        </CustomButton>
      )
    } else {
      return (
        <CustomButton
          className={`
            ${styles.btn}
            ${!!promoLabel ? styles.highlighted : ''}
          `}
          onClick={handleClick}
          loading={planProcessing}
          disabled={subscriptionProcessing}
          largeRadius={true}
          size={'l'}
        >
          Subscribe
        </CustomButton>
      )
    }
  }, [
    isCustomPlan,
    isTrialAvailableForPlan,
    handleClick,
    planProcessing,
    subscriptionProcessing,
    promoLabel,
    plan.trial_price,
  ])

  const renderHighlight = useCallback(() => {
    if (!!promoLabel) {
      return <div className={styles.highlight}>{promoLabel}</div>
    } else {
      return null
    }
  }, [promoLabel])

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div
        className={`
          ${styles.content}
          ${!!promoLabel ? styles.highlighted : ''}
        `}
      >
        {renderHighlight()}

        <div className={styles.price}>
          <div className={styles.priceMain}>$&nbsp;{plan.amount}</div>

          <div className={styles.priceLabel}>/mo.</div>
        </div>

        <div className={styles.name}>
          <span className={!!promoLabel ? styles.highlighted : ''}>
            {plan.name}
          </span>
        </div>

        <div className={styles.info}>
          <div className={styles.infoBlock}>
            <div>API</div>

            <div>from ${plan.prices.api}</div>
          </div>

          <div className={styles.infoBlock}>
            <div>Proxy</div>

            <div>from ${plan.prices.proxy}</div>
          </div>

          <div className={styles.infoBlock}>
            <div>Unblocker</div>

            <div>from ${plan.prices.unblocker}</div>
          </div>
        </div>

        <div className={styles.additionalInfo}>{renderButton()}</div>
      </div>
    </div>
  )
}

export default SubscriptionCard
