import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import styles from './SubscriptionsInfo.module.scss'

import { SandWatch as SandWatchIcon } from '../../../icons'
import CustomModal from 'components/elements/modal/CustomModal'
import TitledCard from 'components/elements/blocks/TitledCard'
import CustomButton from 'components/elements/buttons/CustomButton'
import PlanStatus from 'components/elements/blocks/PlanStatus'
import TabsList from 'components/elements/tabs/TabsList'
import InfoNotice from 'components/elements/blocks/InfoNotice'
import CustomInput from 'components/elements/inputs/CustomInput'
import InputGroup from 'components/elements/forms/InputGroup'

ChartJS.register(ArcElement, Legend)

const balanceData = {
  total: '15.00',
  used: '9.00',
  remain: '6.00',
}

const tabItems = [
  {
    label: 'One-time purchase',
  },
  {
    label: 'Auto top-up',
  },
]

const SubscriptionInfo: React.FC = () => {
  const navigate = useNavigate()

  const [isModalShown, toggleModal] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const handleCloseModal = () => toggleModal(false)

  const handleShowModal = () => toggleModal(true)

  const handleTabChange = (index: number) => setActiveTabIndex(index)

  const currentPlan = {
    name: 'Pay as you go',
    status: 'active',
  }

  const chartOptions = {
    cutout: '80%',
    rotation: 0,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltip: false,
    responsive: true,
    maintainAspectRatio: false,
  }

  const chartData = {
    datasets: [
      {
        data: [balanceData.used, balanceData.remain],
        backgroundColor: ['#E9EAEC', '#26BD6C'],
        borderWidth: 0,
      },
    ],
  }

  const handleGoToPricingClick = () => navigate('/settings/pricing')

  const renderCurrentPlan = () => {
    if (!!currentPlan) {
      return (
        <div>
          <div className={styles.planInfo}>
            <div className={styles.planName}>{currentPlan?.name} </div>

            <PlanStatus
              // @ts-ignore
              status={currentPlan.status}
              className={styles.planStatus}
            />
          </div>

          <CustomButton onClick={handleGoToPricingClick}>
            Change plan
          </CustomButton>
        </div>
      )
    } else {
      return (
        <div>
          <div className={styles.planInfo}>
            <div className={styles.planName}>No active subscription</div>
          </div>

          <CustomButton onClick={handleGoToPricingClick}>
            Choose plan to subscribe
          </CustomButton>
        </div>
      )
    }
  }

  const renderActiveModalTab = () => {
    if (activeTabIndex === 0) {
      return (
        <div>
          <div className={styles.modalFormRow}>
            <span>Add</span>
            <CustomInput
              className={styles.oneTimeInputWrapper}
              inputClassName={styles.oneTimeInput}
            />
            <span>
              USD for <b>$15 (+VAT)</b>
            </span>
          </div>

          <CustomButton wide>Buy</CustomButton>
        </div>
      )
    } else {
      return (
        <div>
          <InputGroup
            className={styles.inputGroup}
            labelTop={'Minimum balance amount, USD'}
          >
            <CustomInput />
          </InputGroup>

          <InputGroup
            className={styles.inputGroup}
            labelTop={'Amount to add when reaching the minimum, USD'}
          >
            <CustomInput innerNotice={'for $15 (+VAT)'} />
          </InputGroup>

          <CustomButton className={styles.inputGroup} wide>
            Activate auto top-up
          </CustomButton>

          <div className={styles.modalBottom}>
            <a href="/" target={'_blank'} rel={'noopener noreferrer'}>
              Learn more
            </a>{' '}
            about auto top-up
          </div>
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <TitledCard title={'Your subscription plan'}>
          {renderCurrentPlan()}
        </TitledCard>

        <TitledCard title={'Your available balance'}>
          <div className={styles.balance}>
            <div className={styles.chartWrapper}>
              <Doughnut data={chartData} options={chartOptions} />

              <span className={styles.chartCenterText}>
                ${balanceData.remain}
              </span>
            </div>

            <div>
              <div className={styles.balanceInfo}>
                Used ${balanceData.used} of ${balanceData.total}
              </div>

              <CustomButton color={'outline'} onClick={handleShowModal}>
                Top up balance
              </CustomButton>
            </div>
          </div>
        </TitledCard>
      </div>

      <CustomModal
        title={'Top up balance'}
        isModalOpen={isModalShown}
        shouldCloseOnOverlayClick
        showCross
        onModalClose={handleCloseModal}
      >
        <>
          <TabsList
            items={tabItems}
            activeTabIndex={activeTabIndex}
            size={'l'}
            color={'blue'}
            className={styles.modalTabs}
            bordered
            wide
            onChange={handleTabChange}
          />

          <InfoNotice
            color={'gray'}
            icon={<SandWatchIcon />}
            className={styles.modalNotice}
          >
            Don’t worry, Traffic you add now will be available next 12 months:
            up to February 28, 2025.
          </InfoNotice>

          {renderActiveModalTab()}
        </>
      </CustomModal>
    </div>
  )
}

export default SubscriptionInfo
