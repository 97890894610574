import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFolderOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.468 15.483a2.05 2.05 0 0 1-1.28.57l-1.365.098a60.6 60.6 0 0 1-8.646 0l-1.261-.09a2.19 2.19 0 0 1-1.999-1.792 20.2 20.2 0 0 1-.185-6.036l.227-1.891a2.233 2.233 0 0 1 2.217-1.967h1.91c.932 0 1.708.665 1.879 1.547h6.929c1.088 0 2.016.79 2.187 1.866l.053.334q.018.105.033.211h.28a1.745 1.745 0 0 1 1.626 2.377l-1.041 2.677a4.9 4.9 0 0 1-1.564 2.096m-.621-7.498.053.334.002.014h-7.76c-.946 0-1.795.582-2.137 1.465l-1.943 5.02-.057-.004a.94.94 0 0 1-.858-.77 19 19 0 0 1-.174-5.662l.228-1.891a.983.983 0 0 1 .975-.866h1.91c.367 0 .664.297.664.664 0 .488.395.883.883.883h7.26c.475 0 .879.344.954.813M6.367 14.91c2.785.194 5.581.192 8.367-.007l1.365-.098.162-.015-.001-.002a3.65 3.65 0 0 0 1.607-1.855l1.04-2.677a.495.495 0 0 0-.46-.674H9.142c-.43 0-.816.265-.971.666z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgFolderOpen
