import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.332 2.156c.416-.23.92-.23 1.336 0l6 3.333c.436.243.707.703.707 1.202v6.618c0 .5-.27.96-.707 1.202l-6 3.333c-.415.23-.92.23-1.336 0l-6-3.333a1.38 1.38 0 0 1-.707-1.202V6.69c0-.499.27-.96.707-1.202zm.729 1.093a.13.13 0 0 0-.122 0l-6 3.333a.13.13 0 0 0-.064.11v6.617c0 .045.025.087.064.109l6 3.333a.13.13 0 0 0 .122 0l6-3.333a.13.13 0 0 0 .064-.11V6.692a.13.13 0 0 0-.064-.109z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.042 10a3.958 3.958 0 1 1 7.916 0 3.958 3.958 0 0 1-7.916 0M10 7.292a2.708 2.708 0 1 0 0 5.416 2.708 2.708 0 0 0 0-5.416"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSettings
