import React from 'react'

import styles from './APIKeySettings.module.scss'

import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'
import CustomButton from 'components/elements/buttons/CustomButton'
import CopyButton from 'components/elements/buttons/CopyButton'

const APIKeySettings: React.FC = () => {
  const proxyAPIKey: string = 'VN2zye0zRF9P3eks'
  const scrapingAPIKey: string = 'CEFzye0zRF9P3eks'
  const unblockerAPIKey: string = 'LPUfcaRF9P3eks'

  const handleGenerateProxyAPIKeyClick = () => {}
  const handleGenerateScrapingAPIKeyClick = () => {}
  const handleGenerateUnblockerAPIKeyClick = () => {}

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>API key</h3>

      <p className={styles.description}>
        Description text or security tips are here
      </p>

      <InputGroup className={styles.inputGroup} labelTop={'Proxy API key'}>
        <CustomInput className={styles.keyInput} value={proxyAPIKey} readOnly />

        <CopyButton
          className={styles.copy}
          text={proxyAPIKey}
          color={'outline'}
        />

        <CustomButton
          color={'outline'}
          onClick={handleGenerateProxyAPIKeyClick}
        >
          Generate new
        </CustomButton>
      </InputGroup>

      <InputGroup className={styles.inputGroup} labelTop={'Scraping API key'}>
        <CustomInput
          className={styles.keyInput}
          value={scrapingAPIKey}
          readOnly
        />

        <CopyButton
          className={styles.copy}
          text={scrapingAPIKey}
          color={'outline'}
        />

        <CustomButton
          color={'outline'}
          onClick={handleGenerateScrapingAPIKeyClick}
        >
          Generate new
        </CustomButton>
      </InputGroup>

      <InputGroup
        className={styles.inputGroup}
        labelTop={'Web Unblocker API key'}
      >
        <CustomInput
          className={styles.keyInput}
          value={unblockerAPIKey}
          readOnly
        />

        <CopyButton
          className={styles.copy}
          text={unblockerAPIKey}
          color={'outline'}
        />

        <CustomButton
          color={'outline'}
          onClick={handleGenerateUnblockerAPIKeyClick}
        >
          Generate new
        </CustomButton>
      </InputGroup>
    </div>
  )
}

export default APIKeySettings
