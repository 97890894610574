import React, { ReactNode, useState, useRef, ReactElement } from 'react'

import styles from './ComboButton.module.scss'

import CustomButton from '../CustomButton'
import { ICustomButtonProps } from '../CustomButton/CustomButton'

import { CaretDown as CaretIcon } from '../../../icons'

import { useOutsideClick } from 'utils/hooks'

export interface IComboActionOption {
  icon?: ReactNode
  label: string
  onClick: () => void
}

export interface IComboButtonProps extends ICustomButtonProps {
  actionOptions: IComboActionOption[]
  disabled?: boolean
  children: string | ReactElement
  className?: string
}

const ComboButton: React.FC<IComboButtonProps> = (props) => {
  const { disabled, actionOptions, color, children, className, ...rest } = props

  const [isDropdownOpened, toggleDropdown] = useState(false)

  const dropdownRef = useRef(null)

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpened) {
      toggleDropdown(false)
    }
  })

  const handleBtnClick = () => {
    toggleDropdown(!isDropdownOpened)
  }

  const handleActionSelect = (option: IComboActionOption) => {
    toggleDropdown(false)
    option.onClick()
  }

  const optionsList =
    !!actionOptions && !!actionOptions.length
      ? actionOptions.map((item, index) => {
          return (
            <a
              className={styles.option}
              key={`combo-button_${index}`}
              onClick={() => handleActionSelect(item)}
            >
              {!!item.icon && (
                <div className={styles.optionIconWrapper}>{item.icon}</div>
              )}
              {item.label}
            </a>
          )
        })
      : null

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
      ref={dropdownRef}
    >
      <CustomButton
        color={color || 'outline'}
        className={styles.actionButton}
        onClick={handleBtnClick}
        {...rest}
      >
        <>
          {children}

          <div
            className={`
              ${styles.arrowWrapper}
              ${isDropdownOpened ? styles.active : ''}
            `}
          >
            <CaretIcon />
          </div>
        </>
      </CustomButton>

      <div
        className={`
          ${styles.dropdown}
          ${isDropdownOpened ? styles.opened : ''}
        `}
      >
        <div className={styles.dropdownContent}>{optionsList}</div>
      </div>
    </div>
  )
}

export default ComboButton
