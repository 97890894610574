import { combineReducers } from 'redux'

import pricingReducer from './pricing/reducer'

const createRootReducer = () =>
  combineReducers({
    pricingReducer,
  })

export default createRootReducer
