import React from 'react'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import ProxyConfigurationForm from '../../ProxyConfigurationForm'

interface IProxyConnectConfigurationProps {
  selectedProxyType: any
  selectedTimeInterval: any
  selectedCountry: any
  selectedRegion: any
  selectedCity: any
  selectedIsp: any
  isBindIPActive: boolean
  activeSessionLengthType: any
  sessionPrefix: string
  proxyTypesOptions: ISelectOption[]
  onProxyTypeChange: (option: ISelectOption) => void
  onCountryChange: (option: ISelectOption) => void
  onRegionChange: (option: ISelectOption) => void
  onCityChange: (option: ISelectOption) => void
  onIspChange: (option: ISelectOption) => void
  onTimeIntervalChange: (option: ISelectOption) => void
  onBindIp: () => void
  onActiveSessionLengthChange: (e: any) => void
  onSessionPrefixChange: (e: any) => void
}

const ProxyQuickConfiguration: React.FC<IProxyConnectConfigurationProps> = (
  props
) => {
  const {
    selectedProxyType,
    selectedCountry,
    selectedRegion,
    selectedCity,
    selectedIsp,
    selectedTimeInterval,
    isBindIPActive,
    activeSessionLengthType,
    sessionPrefix,
    proxyTypesOptions,
    onProxyTypeChange,
    onCountryChange,
    onRegionChange,
    onCityChange,
    onIspChange,
    onTimeIntervalChange,
    onBindIp,
    onActiveSessionLengthChange,
    onSessionPrefixChange,
  } = props

  const handleProxyTypeChange = (option: any) => {
    onProxyTypeChange(option)
  }

  return (
    <>
      <div className={styles.blockHeader}>
        <span className={styles.blockTitle}>Connect configuration</span>
      </div>

      <InputGroup
        className={styles.proxyTypeGroup}
        labelTop={'Proxy pool'}
        description={'Select pool here'}
      >
        <CustomSelect
          onChange={handleProxyTypeChange}
          value={selectedProxyType}
          name={'proxy-type-select'}
          options={proxyTypesOptions}
        />
      </InputGroup>

      <ProxyConfigurationForm
        selectedCountry={selectedCountry}
        selectedRegion={selectedRegion}
        selectedCity={selectedCity}
        selectedIsp={selectedIsp}
        activeSessionLengthType={activeSessionLengthType}
        selectedTimeInterval={selectedTimeInterval}
        isBindIPActive={isBindIPActive}
        sessionPrefix={sessionPrefix}
        onCountryChange={onCountryChange}
        onRegionChange={onRegionChange}
        onCityChange={onCityChange}
        onIspChange={onIspChange}
        onActiveSessionLengthChange={onActiveSessionLengthChange}
        onTimeIntervalChange={onTimeIntervalChange}
        onBindIp={onBindIp}
        onSessionPrefixChange={onSessionPrefixChange}
      />
    </>
  )
}

export default ProxyQuickConfiguration
