import React, { ReactElement } from 'react'

import styles from './PromoBlock.module.scss'

interface IPromoItem {
  label: string
  icon: ReactElement
}

interface IPromoBlockProps {
  className?: string
  title: string
  image: string
  items: IPromoItem[]
}

const PromoBlock: React.FC<IPromoBlockProps> = (props) => {
  const { className, title, image, items } = props

  const itemsList = items.map((item, index) => (
    <div className={styles.item} key={`promo-block-item_${index}`}>
      <div className={styles.itemIconWrapper}>{item.icon}</div>
      {item.label}
    </div>
  ))

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles.title}>{title}</div>

      <div className={styles.content}>{itemsList}</div>
    </div>
  )
}

export default PromoBlock
