import React from 'react'

import styles from './ProxyConfigurationItem.module.scss'

import { EditAlt, TrashAlt } from '../../../icons'
import Flag from 'components/elements/other/Flag'
import CustomButton from 'components/elements/buttons/CustomButton'

export interface IProxyConfigurationItem {
  id: number
  location: {
    label: string
    country: string
    region?: string
    city?: string
    isp?: string
  }
  sessionLength: {
    label: string
    value: string
  }
  sessionType: string
  connectionsCount: number
  sessionPrefix: string
}

interface IProxyConfigurationItemProps {
  item: IProxyConfigurationItem
  onEditClick: () => void
  onDeleteClick: () => void
  className?: string
}

const ProxyConfigurationItem: React.FC<IProxyConfigurationItemProps> = (
  props
) => {
  const { item, className, onEditClick, onDeleteClick } = props

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div className={styles.header}>
        <Flag countryCode={item.location.country} className={styles.flag} />
        <span className={styles.location}>{item.location.label}</span>

        <div className={styles.controls}>
          <CustomButton
            color={'ghost'}
            icon={<EditAlt />}
            onClick={onEditClick}
          />
          <CustomButton
            color={'ghost'}
            icon={<TrashAlt />}
            onClick={onDeleteClick}
          />
        </div>
      </div>

      <div>
        <div className={styles.info}>
          Session length: {item.sessionLength.label}
        </div>

        <div className={styles.info}>{item.connectionsCount} connections</div>
      </div>
    </div>
  )
}

export default ProxyConfigurationItem
