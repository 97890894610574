import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Breadcrumbs.module.scss'

interface IBreadcrumbsItemProps {
  item: {
    label: string
    isActive?: boolean
    to?: string
    href?: string
    onClick?: () => void
  }
  isLast?: boolean
  className?: string
}

export interface IBreadcrumbsProps {
  items: IBreadcrumbsItemProps['item'][]
  className?: string
}

const BreadcrumbItem: React.FC<IBreadcrumbsItemProps> = (props) => {
  const { item, className } = props

  if (!!item.to) {
    return (
      <NavLink
        to={item.to}
        className={`
          ${styles.item}
          ${className ? className : ''}
        `}
      >
        <span className={styles.itemLabel}>{item.label}</span>
      </NavLink>
    )
  } else if (!!item.onClick) {
    return (
      <button
        onClick={item.onClick}
        className={`
          ${styles.item}
          ${item.isActive ? styles.activeItem : ''}
          ${className ? className : ''}
        `}
      >
        <span className={styles.itemLabel}>{item.label}</span>
      </button>
    )
  } else if (!!item.href) {
    return (
      <a
        href={item.href}
        className={`
          ${styles.item}
          ${item.isActive ? styles.activeItem : ''}
          ${className ? className : ''}
        `}
      >
        <span className={styles.itemLabel}>{item.label}</span>
      </a>
    )
  } else {
    return (
      <div
        className={`
          ${styles.item}
          ${item.isActive ? styles.activeItem : ''}
          ${className ? className : ''}
        `}
      >
        <span className={styles.itemLabel}>{item.label}</span>
      </div>
    )
  }
}

export default BreadcrumbItem
