import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { IntercomProvider } from 'react-use-intercom'
import { useDispatch } from 'react-redux'

import configureStore from 'store'
import { setupOrbToken } from 'utils/request'
import { PricingActionsTypes } from 'store/pricing/actions'

import './App.scss'

import Routes from './Routes'

const store = configureStore()
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || ''

const App = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  )
}

const AppContent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    setupOrbToken()
    dispatch({ type: PricingActionsTypes.PRICING_GET_PRICES })
  }, [dispatch])

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      /*autoBootProps={{
        userHash: intercomHash,
        email: userEmail,
        userId: userHash,
      }}*/
      autoBoot
      // onShow={handleMessengerShowing}
    >
      <Routes />

      <ToastContainer
        // closeButton={<CloseToastButton/>}
        hideProgressBar={true}
        draggable={false}
        // className="toast-custom-container"
      />
    </IntercomProvider>
  )
}

export default App
