import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './NavItem.module.scss'

export interface INavItem {
  icon: any
  label: string
  to?: string
  href?: string
  isBlank?: boolean
  onClick?: () => void
  items?: INavItem[]
}

interface INavItemProps {
  item: INavItem
  label?: string
  isLabelShown?: boolean
  className?: string
}

const NavItem: React.FC<INavItemProps> = (props) => {
  const { item, label, isLabelShown, className } = props

  if (!!item.to) {
    return (
      <NavLink
        to={item.to}
        className={({ isActive }) =>
          [
            styles.item,
            isLabelShown ? styles.labeled : '',
            isActive ? styles.active : '',
            className ? className : '',
          ].join(' ')
        }
      >
        <span className={styles.iconWrapper}>{item.icon}</span>

        <span className={styles.label}>{label}</span>
      </NavLink>
    )
  } else if (!!item.href) {
    return (
      <a
        href={item.href}
        target={item.isBlank ? '_blank' : '_self'}
        rel={item.isBlank ? 'noopener noreferrer' : ''}
        className={`
        ${styles.item}
        ${isLabelShown ? styles.labeled : ''}
        ${className ? className : ''}
      `}
      >
        <span className={styles.iconWrapper}>{item.icon}</span>

        <span className={styles.label}>{label}</span>
      </a>
    )
  } else if (!!item.onClick) {
    return (
      <button
        onClick={item.onClick}
        className={`
        ${styles.item}
        ${isLabelShown ? styles.labeled : ''}
        ${className ? className : ''}
      `}
      >
        <span className={styles.iconWrapper}>{item.icon}</span>

        <span className={styles.label}>{label}</span>
      </button>
    )
  } else {
    return null
  }
}

export default NavItem
