import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.667 7.917a1.042 1.042 0 1 0 0 2.083 1.042 1.042 0 0 0 0-2.083M10 7.917A1.042 1.042 0 1 0 10 10a1.042 1.042 0 0 0 0-2.083M12.292 8.958a1.042 1.042 0 1 1 2.083 0 1.042 1.042 0 0 1-2.083 0"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.417 3.827a42 42 0 0 0-6.749-.033l-.16.012a4.11 4.11 0 0 0-3.8 4.096V15a.625.625 0 0 0 .921.55L6.89 13.8c.15-.081.32-.124.492-.124h7.48c.944 0 1.752-.675 1.922-1.603.342-1.88.37-3.806.08-5.696l-.086-.557a2.22 2.22 0 0 0-2.007-1.876zM6.762 5.04c2.18-.164 4.37-.153 6.549.033l1.353.115c.447.038.81.377.878.82l.085.558a16 16 0 0 1-.074 5.282.7.7 0 0 1-.691.577h-7.48c-.38 0-.752.094-1.086.273l-2.338 1.256V7.903a2.86 2.86 0 0 1 2.643-2.85z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgChat
