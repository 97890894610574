import React, { InputHTMLAttributes } from 'react'

import styles from './CustomToggle.module.scss'

export interface IToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  labelLeft?: string
  labelRight?: string
  id: string
}

const CustomToggle: React.FC<IToggleProps> = (props) => {
  const {
    labelLeft,
    labelRight,
    className,
    checked,
    value,
    id,
    name,
    disabled,
    onChange,
    onBlur,
    ...rest
  } = props

  return (
    <label
      className={`
        ${styles.container}
        ${disabled ? styles.disabled : ''}
        ${className ? className : ''}`}
    >
      {!!labelLeft && <div className={styles.labelLeft}>{labelLeft}</div>}

      <input
        type="checkbox"
        name={name}
        className={styles.input}
        value={value}
        checked={checked}
        id={id}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />

      <label
        className={`
          ${styles.box}
          ${!!checked ? styles.checked : ''}
        `}
        htmlFor={id}
      >
        <div className={styles.inner} />
        <div className={styles.switch} />
      </label>

      {!!labelRight && <div className={styles.labelRight}>{labelRight}</div>}
    </label>
  )
}

export default CustomToggle
