import Flags from 'country-flag-icons/react/3x2'
import React from 'react'

type FlagProps = {
  countryCode: string
  className?: string
}

const Flag = ({ countryCode, className, ...rest }: FlagProps) => {
  const FlagComponent = Flags[countryCode.toUpperCase() as keyof typeof Flags]
  return (
    <div className={className ? className : ''} {...rest}>
      <FlagComponent />
    </div>
  )
}

export default Flag
