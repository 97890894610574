import React, { useState } from 'react'

import styles from '../ProxyContent/ProxyContent.module.scss'

import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import ProxyListConfiguration from './ProxyListConfiguration'
import ProxyListResult from './ProxyListResult'
import { Home as HomeIcon } from '../../../icons'

const proxyTypesOptions = [
  {
    label: 'Residential proxy',
    value: 'residential',
    icon: <HomeIcon />,
  },
  {
    label: 'Mobile proxy',
    value: 'mobile',
    icon: <HomeIcon />,
  },
]

const configurationItems = [
  {
    id: 12312,
    location: {
      label: 'Turkey',
      country: 'tr',
      region: 'antalya',
      city: 'trabzon',
    },
    sessionType: 'same',
    sessionLength: {
      label: '20 min.',
      value: '1200',
    },
    connectionsCount: 142,
    sessionPrefix: 'kek',
  },
]

const ProxyListGenerator: React.FC = () => {
  const [selectedProxyType, setProxyType] = useState<ISelectOption | null>(
    proxyTypesOptions[0]
  )

  const handleProxyTypeChange = (option: any) => {
    setProxyType(option)
  }

  return (
    <div className={styles.container}>
      <div
        className={`
          ${styles.contentBlock}
          ${styles.leftSide}
        `}
      >
        <ProxyListConfiguration
          selectedProxyType={selectedProxyType}
          configurationItems={configurationItems}
          onProxyTypeChange={handleProxyTypeChange}
        />
      </div>

      <div
        className={`
          ${styles.contentBlock}
          ${styles.rightSide}
        `}
      >
        <ProxyListResult
          selectedProxyType={selectedProxyType}
          configurationItems={configurationItems}
        />
      </div>
    </div>
  )
}

export default ProxyListGenerator
