import React, { useState } from 'react'
import { useIntercom } from 'react-use-intercom'

import styles from './PlansList.module.scss'

import SubscriptionCard from 'components/modules/pricing/SubscriptionCard'

import { ISubscriptionPlan, TProxyTypes } from 'models/pricing'
import { popularPlanId } from 'utils/constants'

interface IPlansListProps {
  plansList: any[]
  isTrialAvailable?: boolean
  isEnterpriseActive: boolean
  className?: string
}

const customOfferPlan = {
  name: 'Custom Offer',
  amount: '5999+',
  type: 'general',
  prices: {
    api: '1.0',
    proxy: '0.5',
    unblocker: '2.0',
  },
  trial_price: 1.99,
}

const PlansList: React.FC<IPlansListProps> = (props) => {
  const { plansList, isTrialAvailable, isEnterpriseActive, className } = props
  const { show } = useIntercom()

  const [activePlanType /*, setActivePlanType*/] = useState<TProxyTypes | null>(
    null
  )
  const [processingPlanId /*, setProcessingPlanId*/] = useState<number | null>(
    null
  )

  // const getCheckoutId = () => {}

  const handleShowIntercom = () => show()

  const handlePlanSelect = () => {}

  const renderPlansItemsList = () => {
    if (!!plansList.length) {
      return plansList.map((plan: ISubscriptionPlan) => (
        <SubscriptionCard
          key={plan.id}
          plan={plan}
          onPlanClick={handlePlanSelect}
          isTrialAvailable={isTrialAvailable}
          // subscriptionProcessing={subscriptionProcessing}
          promoLabel={popularPlanId.includes(plan.id) ? 'Most popular' : null}
          planProcessing={plan.id === processingPlanId}
          activePlanType={activePlanType}
          className={styles.planItem}
        />
      ))
    } else {
      return null
    }
  }

  /*if (plansFetching || trialAvailabilityChecking) {
    return <Spinner classname={styles.spinnerContainer} />
  }*/

  if (!plansList?.length) return null

  return (
    <section
      className={`
        ${styles.plansSection}
        ${className || ''}
      `}
    >
      <div className={styles.plansWrapper}>
        <div className={styles.plansRow}>
          {renderPlansItemsList()}
          {isEnterpriseActive && (
            <SubscriptionCard
              plan={customOfferPlan}
              onPlanClick={handleShowIntercom}
              isTrialAvailable={isTrialAvailable}
              isCustomPlan={true}
              className={styles.planItem}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default PlansList
