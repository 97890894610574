import * as React from 'react'
import type { SVGProps } from 'react'
const SvgStack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.462 2.8a2.13 2.13 0 0 0-1.88-.005C7.42 3.911 5.46 5.19 3.742 6.61c-.265.22-.454.538-.45.913.003.374.195.689.459.905 1.703 1.392 3.665 2.673 5.786 3.78.56.292 1.317.297 1.88.005 2.162-1.115 4.121-2.395 5.839-3.814.265-.22.454-.539.45-.914-.002-.374-.195-.688-.459-.904-1.703-1.393-3.664-2.674-5.786-3.78m-1.307 1.106a.9.9 0 0 1 .729.002c2.024 1.057 3.89 2.273 5.506 3.586-1.626 1.334-3.486 2.545-5.545 3.609a.9.9 0 0 1-.729-.003c-2.024-1.057-3.89-2.272-5.506-3.585 1.626-1.334 3.486-2.546 5.545-3.61"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M18.164 10.582a.625.625 0 0 1-.199.86l-5.09 3.178c-.69.432-1.547.63-2.375.63s-1.685-.198-2.376-.63l-5.033-3.14a.625.625 0 1 1 .661-1.061l5.034 3.141c.453.283 1.07.44 1.714.44.645 0 1.26-.157 1.714-.44l5.09-3.177a.625.625 0 0 1 .86.199"
    />
    <path
      fill="currentColor"
      d="M18.164 13.71a.625.625 0 0 1-.199.862l-4.407 2.752c-.882.55-1.984.81-3.058.81s-2.176-.26-3.058-.81l-4.351-2.716a.625.625 0 1 1 .661-1.06l4.352 2.716c.644.402 1.506.62 2.396.62s1.752-.218 2.396-.62l4.407-2.752a.625.625 0 0 1 .861.199"
    />
  </svg>
)
export default SvgStack
