import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={10}
    fill="none"
    viewBox="0 0 14 10"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.03.97a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 1.06-1.06L5.5 7.44 11.97.97a.75.75 0 0 1 1.06 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCheck
