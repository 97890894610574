import React from 'react'

import styles from './UnblockerPage.module.scss'

import MainHeader from 'components/navigation/MainHeader'

const UnblockerPage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'Your products',
      to: '/',
    },
    {
      label: 'Unblocker',
      isActive: true,
    },
  ]

  return (
    <>
      <MainHeader crumbsItems={crumbsItems} />
      <section className={styles.container}>Unblocker page</section>
    </>
  )
}

export default UnblockerPage
