import React from 'react'

import { CaretRight as CaretRightIcon } from '../../icons'
import BreadcrumbItem from './BreadcrumbItem'

import styles from './Breadcrumbs.module.scss'

interface IBreadcrumbsItemProps {
  item: {
    label: string
    to?: string
    onClick?: () => void
  }
  className?: string
}

export interface IBreadcrumbsProps {
  items: IBreadcrumbsItemProps['item'][]
  className?: string
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = (props) => {
  const { items, className } = props

  const itemsList =
    !!items && !!items.length
      ? items.map((item, index) => (
          <React.Fragment key={index}>
            <BreadcrumbItem item={item} isLast={index + 1 === items.length} />
            {index + 1 < items.length ? (
              <div className={styles.arrowWrapper}>
                <CaretRightIcon />
              </div>
            ) : null}
          </React.Fragment>
        ))
      : null

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      {itemsList}
    </div>
  )
}

export default Breadcrumbs
