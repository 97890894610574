import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.5 2.708A4.79 4.79 0 0 0 2.706 7.5v5.922a.625.625 0 0 0 1.25 0V7.5A3.54 3.54 0 0 1 7.5 3.958h5.845a.625.625 0 1 0 0-1.25z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.335 5.661a37 37 0 0 0-8.171 0 1.676 1.676 0 0 0-1.479 1.467 35.5 35.5 0 0 0 0 8.244c.091.775.71 1.38 1.479 1.467 2.7.302 5.47.302 8.171 0a1.676 1.676 0 0 0 1.479-1.467c.32-2.739.32-5.505 0-8.244a1.676 1.676 0 0 0-1.479-1.467M7.303 6.903a35.7 35.7 0 0 1 7.893 0 .426.426 0 0 1 .376.37 34.2 34.2 0 0 1 0 7.954.426.426 0 0 1-.376.37 35.7 35.7 0 0 1-7.893 0 .426.426 0 0 1-.376-.37 34.2 34.2 0 0 1 0-7.954.426.426 0 0 1 .376-.37"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCopy
