import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.833 2.5a.833.833 0 1 0-1.666 0v.625h-.465a3.51 3.51 0 0 0-3.504 3.292l-.185 2.945a6.15 6.15 0 0 1-1.09 3.128 1.347 1.347 0 0 0 .946 2.107l2.84.34v.896a2.292 2.292 0 1 0 4.583 0v-.895l2.839-.34a1.347 1.347 0 0 0 .945-2.108 6.15 6.15 0 0 1-1.09-3.128l-.184-2.945a3.51 3.51 0 0 0-3.504-3.292h-.465zm-2.13 1.875a2.26 2.26 0 0 0-2.258 2.12L6.261 9.44a7.4 7.4 0 0 1-1.311 3.764.097.097 0 0 0 .068.152l3.115.374c1.24.149 2.494.149 3.734 0l3.115-.374a.097.097 0 0 0 .068-.152 7.4 7.4 0 0 1-1.31-3.764l-.185-2.945a2.26 2.26 0 0 0-2.257-2.12zM10 16.875a1.04 1.04 0 0 1-1.042-1.042v-.625h2.084v.625c0 .576-.467 1.042-1.042 1.042"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgNotification
